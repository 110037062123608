import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';
import { FileStorageSetting, UploadFileStorage } from 'src/app/models/filestorage.model';
import { User, UserDocument } from 'src/app/models/user.model';
import { FileStorageService } from '../filestorage/filestorage.service';
import { UserService } from '../user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UtilsService } from '../util/util-service';
import { environment } from 'src/environments/environment';

export interface IUserDocumentUpload {
  FileType: string,
  UploadType: string
}

@Injectable({
    providedIn: 'root'
})
export class DocumentService {  
  currentUser: User | undefined;  
  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _fileStorageService: FileStorageService,
    private _localizationService: LocalizationService,
    private _utilsService: UtilsService
  ){
    this.loadAuthData();
  }
  
  private _fileStorageSettings: FileStorageSetting | undefined;
  public get fileStorageSettings(): FileStorageSetting | undefined {
    return this._fileStorageSettings;
  }
  public set fileStorageSettings(settings: FileStorageSetting | undefined) {
    this._fileStorageSettings = settings;
  }

  loadAuthData() {
    return Promise.all([
      this._authService.getUser()
      .then(
        user => this.currentUser = user,
        _ => console.log("Failed to get user")
      ),
      this._authService.getFileSettings().then(
        fs => this.fileStorageSettings = fs,
        _ => console.log("Failed to get file storage setting")
      )
    ]);
  }
  
  getFileAndUploadType(file: File): IUserDocumentUpload | undefined {

    let fileMimeType = this._utilsService.checkMimeType(file);

    if (!fileMimeType){
      alert(this._localizationService
        .translate("userprofile_invalid_filetype")
        .replace('{supported_formats}', `${this.fileStorageSettings?.supportedDocuments!.toLocaleUpperCase()},${this.fileStorageSettings?.supportedImages!.toLocaleUpperCase()}`));
      return;
    }

    let fileType: string = '';
    let uploadType = '';

    // Check supported types for image
    let imgFormats = this.fileStorageSettings?.supportedImages?.replace(/^,+|,+$/g,'').split(",").join("|");
    if (imgFormats && imgFormats.length > 0) {
      let matches = fileMimeType!.match(new RegExp(`(${imgFormats})$\w?`));
      if (matches != null) {
        if (file.size > ((this.fileStorageSettings?.maxImageSizeInMbs ?? environment.maxFileSize) * 1024 * 1024) ){
          alert(this._localizationService
            .translate("user_documents_addfile_exceedmaxfilesize")
            .replace('{file_size}', (this.fileStorageSettings?.maxImageSizeInMbs ?? environment.maxFileSize).toString()));
          return;
        }
        fileType = matches[0];
        uploadType = 'img';
      }
    }
    
    // Check supported types for document
    if (fileType.length == 0) {
      let docFormats = this.fileStorageSettings?.supportedDocuments?.replace(/^,+|,+$/g,'').split(",").join("|");
      if (docFormats && docFormats.length > 0) {
        let matches = fileMimeType!.match(new RegExp(`(${docFormats})$\w?`));
        if (matches != null) {
          if (file.size > ((this.fileStorageSettings?.maxDocumentSizeInMbs ?? environment.maxFileSize) * 1024 * 1024) ){
            alert(this._localizationService
              .translate("user_documents_addfile_exceedmaxfilesize")
              .replace('{file_size}', (this.fileStorageSettings?.maxDocumentSizeInMbs ?? environment.maxFileSize).toString()));
            return;
          }
          fileType = matches[0];
          uploadType = 'doc';
        }
      }
    }

    if (fileType.length == 0) {
      alert(this._localizationService
        .translate("userprofile_invalid_filetype")
        .replace('{supported_formats}', `${this.fileStorageSettings?.supportedDocuments!.toLocaleUpperCase()},${this.fileStorageSettings?.supportedImages!.toLocaleUpperCase()}`));
      return;
    }
    
    return {
      FileType: fileType, 
      UploadType: uploadType
    } as IUserDocumentUpload;
  }

  uploadUserDocument(fileType: string, docType: string, file: File): Observable<UserDocument|null> {               
    var newFile = {
      fileType: fileType,
      fileName: file.name
    } as UploadFileStorage;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Observable((observer: Subscriber<any>): void => {
      reader.onloadend = () => {        
        if (reader.result) {          
          newFile.file = reader.result.toString().replace(/^.+,/, '');
          this._fileStorageService.UploadDocument(newFile)
          .subscribe({
            next: result => {
              if (result) {
                return this._userService.UploadDocument({
                  id: undefined,
                  userId: this.currentUser?.id ?? "",
                  fileStorageId: result.id ?? "",
                  documentType: docType,
                  documentName: result.fileName ?? ""
                }).subscribe({
                  next: (userDocument => { 
                    observer.next(userDocument);
                    observer.complete();
                  }),
                  error: err => {
                    alert(this._localizationService.translate('user_documents_addfile_save_error'));
                    console.log(err);
                    observer.error(err);
                  }
                });
              } else return null;
            },
            error: err => {
              if (err.error && err.error.errorCode) {
                alert(this._localizationService.translate(err.error.errorCode));
              }
              else {
                alert(this._localizationService.translate("user_documents_addfile_save_error"));
              }
              console.log(err);
              observer.error(err);
            }
          });
        }
      }
    });
  }

  uploadUserImage(fileType: string, docType: string, file: File): Observable<UserDocument|null> {    
    var newFile = {
        fileType: fileType,
        fileName: file.name
      } as UploadFileStorage;    
    const reader = new FileReader();
    reader.readAsDataURL(file);    
    return new Observable((observer: Subscriber<any>): void => {
      reader.onloadend = () => {        
        if (reader.result) {
          let updatedDataUrl = fileType === "jpg" 
            ? this._utilsService.removeExifMetadataFromDataUrl(reader.result.toString())
            : reader.result.toString();
          newFile.file = updatedDataUrl.replace(/^.+,/, '');
          this._fileStorageService.UploadImage(newFile)
          .subscribe({
            next: result => {
              if (result) {
                return this._userService.UploadDocument({
                  id: undefined,
                  userId: this.currentUser?.id ?? "",
                  fileStorageId: result.id ?? "",
                  documentType: docType,
                  documentName: result.fileName ?? ""
                }).subscribe({
                  next: (userDocument => { 
                    observer.next(userDocument);
                    observer.complete();
                  }),
                  error: err => {
                    alert(this._localizationService.translate('user_documents_addfile_save_error'));
                    console.log(err);
                    observer.error(err);
                  }
                });
              } else return null;
            },
            error: err => {
              if (err.error && err.error.errorCode) {
                alert(this._localizationService.translate(err.error.errorCode));
              }
              else {
                alert(this._localizationService.translate("user_documents_addfile_save_error"));
              }
              console.log(err);
              observer.error(err);
            }
          });
        } 
      }      
    });
  }

  downloadUserDocument(documentId: string): Observable<string|null> {               
    return new Observable((observer: Subscriber<any>): void => {            
      this._userService.getDocument(documentId)
      .subscribe({
        next: userDocument => {
          if (userDocument) {
            return this._fileStorageService.download(userDocument.fileStorageId)
            .subscribe({
              next: (fileStorage => { 
                observer.next(`data:image/${fileStorage.fileType};base64,${fileStorage.file}`);
                observer.complete();
              }),
              error: err => {
                if (err.error && err.error.errorCode) {
                  alert(this._localizationService.translate(err.error.errorCode));
                }
                else {
                  alert(this._localizationService.translate("user_documents_downloadfile_error"));
                }
                console.log(err);
                observer.error(err);
              }
            });
          } else return null;
        },
        error: err => {
          alert(this._localizationService.translate('user_documents_downloadfile_error'));
          console.log(err);
          observer.error(err);
        }
      });
    });
  }
}
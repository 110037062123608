<div *ngIf="inductionConfig?.visitorType">
    <div><label class="reasonForVisitLabel" id="reasonForVisitLabel">{{'induction_lbl_reasonforvisit' | translate}}</label></div>
    <div><label class="joblabel" id="reasonForVisitLabel">{{'induction_lbl_reasonforvisit_selectoptions' | translate}}</label></div>
    <div class="divider"></div>
    <div fxLayout="row" *ngFor="let reason of reasonsForVisit">
        <div fxLayout="column" fxFlex="100">
            <div class="divider"></div>
            <button mat-stroked-button id="btn_type{{reason.id}}" (click)="onReasonForVisitSelect(reason)" 
                [ngClass]="{'selected' : reason.selected, 'unselected': !reason.selected}">
                <span fxFlex="1 1 auto" fxFlexOrder="1">{{ reason.displayDescription }}</span>
                <mat-icon class="maticon" *ngIf="reason.selected" fxFlex="0 0 auto" fxFlexOrder="2">task_alt</mat-icon>
            </button>
        </div>
    </div>
</div>
<div *ngIf="inductionConfig?.visitorType == undefined || inductionConfig?.visitorType == null">
    <div *ngIf="jobsAllocated.length > 0">
        <label class="reasonForVisitLabel" id="reasonForVisitLabel">{{'reasonforvisit_lbl_allocatedjob' | translate}}</label>
        <div class="divider"></div>
        <div *ngFor="let job of jobsAllocated">
            <div fxLayout="column" fxFlex="100" [ngClass]="{'disableDiv': nonCityFMWorkChecked}">
                <div fxLayout="row" fxFlex="100" [ngClass]="{'disableDiv': (job.referenceNumber == fastLinkJob?.referenceNumber)}">
                    <div fxFlex="10">
                        <mat-checkbox value={{job.referenceNumber}} id="job{{job.referenceNumber}}" checked="{{job.reasonForVisitCheck == true}}" (change)="onJobCheck($event.checked,job)" disabled="{{nonCityFMWorkChecked}}" > </mat-checkbox>
                    </div>
                    <div fxFlex="90">
                        <mat-expansion-panel [ngClass]="{'disableDiv': nonCityFMWorkChecked || (job.referenceNumber == fastLinkJob?.referenceNumber)}">
                            <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" class="expansion-header">
                                <mat-panel-title class="name" fxFlex="100" >
                                    <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
                                        <div *ngIf="job.subSite != null" class="joblabel">
                                            {{job.subSite}}
                                        </div>
                                        <div class="joblabel color-city-neutral">
                                            #{{job.referenceNumber}} 
                                        </div>
                                        <div class="faultservicelabel color-dark-blue">
                                            {{job.faultServiceName}}
                                        </div>
        
                                        <div *ngIf="job.eta != null" fxLayout="row" fxFlex="100">
                                            <div class="job-icon">
                                                <mat-icon class="color-city-blue">today</mat-icon>
                                            </div>
                                            <div *ngIf="isReactiveJob(job)"  class="color-city-blue eta-lbl-font">
                                                ETA {{job.eta | date:'dd-MM-yyyy hh:mm a' }} 
                                            </div>
                                            <div *ngIf="isPPMJob(job)"  class="color-city-blue eta-lbl-font">
                                                Due On {{job.eta | date:'dd-MM-yyyy' }} 
                                            </div>
                                        </div>
                                    </div>                               
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxLayout="column" fxFlex="100">
                                <div class="expandedheaderlabel color-city-neutral">
                                    {{'common_lbl_department' | translate}}
                                </div>
                                <div class="expandedlabel color-dark-blue">
                                    {{job.departmentName}}
                                </div>
                                <div class="expandedheaderlabel color-city-neutral">
                                    {{'common_lbl_notes' | translate}}
                                </div>
                                <div class="expandedlabel color-dark-blue">
                                    {{job.notes}}
                                </div>
            
                            </div>
                        </mat-expansion-panel>
                        <div class="divider"></div>
                    </div>
                 
                </div>
                <mat-divider></mat-divider>
            </div>
           
        </div>
    </div>
    <div class="divider"></div>
    <div *ngIf="jobsParentUser.length > 0">
        <label class="reasonForVisitLabel" id="reasonForVisitLabel">{{ ('reasonforvisit_lbl_parentjob' | translate).replace("{site_name}", selectedSite?.name) }}</label>
        <div class="divider"></div>
        <div *ngFor="let job of jobsParentUser">
            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" class="expansion-header">
                
                    <mat-panel-title class="name" fxFlex="100" >
                        <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
                            <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="65">
                                <div *ngIf="job.subSite != null" class="joblabel">
                                    {{job.subSite}}
                                </div>
                                <div class="joblabel color-city-neutral">
                                    #{{job.referenceNumber}} 
                                </div>
                                <div class="faultservicelabel color-dark-blue">
                                    {{job.faultServiceName}}
                                </div>

                                <div *ngIf="job.eta != null" fxLayout="row" fxFlex="100">
                                    <div class="job-icon">
                                        <mat-icon class="color-city-blue">today</mat-icon>
                                    </div>
                                    <div *ngIf="isReactiveJob(job)"  class="color-city-blue eta-lbl-font">
                                        ETA {{job.eta | date:'dd-MM-yyyy hh:mm a' }} 
                                    </div>
                                    <div *ngIf="isPPMJob(job)"  class="color-city-blue eta-lbl-font">
                                        Due On {{job.eta | date:'dd-MM-yyyy' }} 
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="35" fxLayoutAlign="end center">
                                <button mat-flat-button class="pickup" (click)="pickupJobOnClick(job)" id="btn_pickup">
                                    {{ 'reasonforvisit_lbl_assignjobtome' | translate }}
                                </button>
                            </div>
                        </div>
                 
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="column" fxFlex="100">
                    <div class="expandedheaderlabel color-city-neutral">
                        {{'common_lbl_department' | translate}}
                    </div>
                    <div class="expandedlabel color-dark-blue">
                        {{job.departmentName}}
                    </div>
                    <div class="expandedheaderlabel color-city-neutral">
                        {{'common_lbl_notes' | translate}}
                    </div>
                    <div class="expandedlabel color-dark-blue">
                        {{job.notes}}
                    </div>

                </div>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            <div class="divider"></div>
        </div>
    </div>
    <div class="divider"></div>
    <div class="divider"></div>
    <div>
        <div fxLayout="row" fxFlex="100" [ngClass]="{'disableDiv': cityFMWorkChecked}">
            <div fxFlex="10">
                <mat-checkbox class="joblabel color-city-neutral" checked={{nonCityFMWorkChecked}} id="noncifyfmwork_checkbox" (change)="nonCityFMWorkCheck($event.checked)" disabled="{{cityFMWorkChecked}}" > </mat-checkbox>
            </div>
            <div fxFlex="90">
                <mat-label class="joblabel color-city-neutral">{{nonCityFMWorkLabel}}</mat-label>
            </div>
        </div>
    </div>
</div>
<div class="dialog-container">
    <label class="addEditVisitReasonsPopupHeader" id="addEditVisitReasonsPopupHeader">
        {{ ((!isEditMode ? 'visitor_type_management_reason_for_visit_addEdit_add_popup_header' :
        'visitor_type_management_reason_for_visit_addEdit_edit_popup_header') | translate ) }}
    </label>
    <form [formGroup]="formGroup">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="100%">
                <div class="input-field" fxLayout="column">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label class="color-city-blue">
                            {{ 'visitor_type_management_reason_for_visit_addEdit_form_lbl_description' | translate }}
                        </mat-label>
                        <input matInput
                            placeholder="{{ 'visitor_type_management_reason_for_visit_addEdit_form_lbl_description' | translate }}"
                            formControlName="description" required id="input_description" />
                        <mat-error *ngIf="hasError(formGroup, 'description')">
                            {{ 'visitor_type_management_reason_for_visit_addEdit_form_description_validator' | translate
                            }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="input-field" fxLayout="column"  *ngFor="let lang of languages">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label class="color-city-blue">
                            {{ 'visitor_type_management_reason_for_visit_addEdit_form_lbl_description' | translate }}  {{lang.description}}
                        </mat-label>
                        <input matInput
                            placeholder="{{ 'visitor_type_management_reason_for_visit_addEdit_form_lbl_description' | translate }}"
                            formControlName="{{lang.code.replace('-', '_')}}" id="input_reason_description_{{lang.code.replace('-', '_')}}" />
                    </mat-form-field>
                </div>

                <div class="divider"></div>
                <button type="submit" mat-raised-button id="saveVisitReasons_btn" data-testid="save_visit_reasons_btn"
                    (click)="saveOnClick()" [disabled]="disableSaveOnClick()">
                    {{ (('visitor_type_management_reason_for_visit_addEdit_popup_save_btn') | translate ) }}
                </button>
                <div class="divider"></div>
                <button mat-stroked-button class="color-city-blue btn-border-color"
                    data-testid="cancel_visitor_type_deletion_tbn" (click)="dismissDialog()">
                    {{ 'visitor_type_management_reason_for_visit_addEdit_popup_cancel_btn' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Preferences } from '@capacitor/preferences';
import { Module, User } from 'src/app/models/user.model';
import { AppPlatform, CompanyType, StorageKey } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { UtilsService } from 'src/app/services/util/util-service';
import { BrowserService } from 'src/app/services/browser/browser.service';
import { FileStorageSetting } from '@app/models/filestorage.model';
import { Router } from '@angular/router';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { Browser } from '@capacitor/browser';
import { ReferenceService } from '../reference/reference.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../user/user.service';
import { AndroidOptions, GenericOAuth2, IosOptions, OAuth2AuthenticateOptions, OAuth2RefreshTokenOptions, WebOption } from '@capacitor-community/generic-oauth2';
import { WebUtils } from '@capacitor-community/generic-oauth2/dist/esm/web-utils.js';
import { Console } from 'console';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private oauth2Options: OAuth2AuthenticateOptions;
  private oauth2RefreshOptions: OAuth2RefreshTokenOptions;
  readonly KEY = StorageKey[StorageKey.USER];
  readonly KEY_TOKEN = StorageKey[StorageKey.TOKEN];
  readonly KEY_REFRESHTOKEN = StorageKey[StorageKey.REFRESHTOKEN];
  readonly KEY_MODULE = StorageKey[StorageKey.MODULE];
  readonly KEY_FILESETTINGS = StorageKey[StorageKey.FILESETTINGS];
  private isResourceAdminState = new BehaviorSubject<boolean>(false);

  constructor(
    private _jwtHelper: JwtHelperService,
    @Inject(DOCUMENT) private document: any,
    private _utilsService: UtilsService,
    private _router: Router,
    private _localizationService: LocalizationService,
    private _browserService: BrowserService,
    private _referenceService: ReferenceService,
    private _userService: UserService
  ) {

    this.oauth2Options = {
      appId: environment.authB2C.appId,
      scope: environment.authB2C.scope,
      pkceEnabled: true,
      web: {
        responseType: 'token',
        accessTokenEndpoint: '',
        redirectUrl: this.appendRedirectPath(window.location.origin),
        windowOptions: 'height=600, Width=400, left=0, top=0',
        windowTarget: '_self'
      },
      android: {
        responseType: 'code',
        redirectUrl: 'cityonsite://auth'
      },
      ios: {
        pkceEnabled: true,
        responseType: 'code',
        redirectUrl: 'cityonsite://auth'
      }
    };

    this.oauth2RefreshOptions = {
      appId: environment.authB2C.appId,
      scope: environment.authB2C.scope,
      accessTokenEndpoint: environment.authB2C.accessTokenEndpoint.replace('{azure_policy}', environment.b2cSignInPolicy),
      refreshToken: ''
    };
  }

  public async getUser(): Promise<User> {
    const item = await Preferences.get({ key: this.KEY });
    var obj;
    if (item.value != null) {
      obj = JSON.parse(item.value);
    }
    return obj;
  }

  async setUser(user: User) {
    await Preferences.set({
      key: StorageKey[this.KEY],
      value: JSON.stringify(user),
    });
  }

  public async getUserModule(): Promise<Module[]> {
    const item = await Preferences.get({ key: this.KEY_MODULE });
    var obj;

    if (item.value != null) {
      obj = JSON.parse(item.value);
    }
    return obj;
  }

  async setUserModule(module: Module[]) {
    let filteredModule: Module[] = [];

    if (this._utilsService.isAndroid()) {
      filteredModule = module.filter(m => m.enableAndroid);
    } else if (this._utilsService.isIOS()) {
      filteredModule = module.filter(m => m.enableIOS);
    } else if (this._utilsService.isWebBrowser() || this._utilsService.isEdgeBrowser()) {
      filteredModule = module.filter(m => m.enableWeb);
    }

    await Preferences.set({
      key: StorageKey[this.KEY_MODULE],
      value: JSON.stringify(filteredModule),
    });
  }

  public async getFileSettings(): Promise<FileStorageSetting> {
    const item = await Preferences.get({ key: this.KEY_FILESETTINGS });
    var obj;
    if (item.value != null) {
      obj = JSON.parse(item.value);
    }
    return obj;
  }

  async setFileSettings(setting: FileStorageSetting) {
    await Preferences.set({
      key: StorageKey[this.KEY_FILESETTINGS],
      value: JSON.stringify(setting),
    });
  }

  public async getTokenFromStorage(): Promise<any> {
    return Preferences.get({ key: this.KEY_TOKEN })
      .then(item => {
        if (item?.value) {
          if (this.isTokenValid(item.value)) {
            return item?.value
          } else {
            if (!this.isDevice()) {
              return Preferences.clear()
                .then(_ => { return null })
            } else {
              return Preferences.get({ key: this.KEY_REFRESHTOKEN })
                .then(refreshToken => {
                  if (refreshToken && refreshToken.value != null && refreshToken.value != '') {
                    this.oauth2RefreshOptions.refreshToken = refreshToken.value;
                    try {
                      return GenericOAuth2.refreshToken(this.oauth2RefreshOptions)
                        .then(
                          resolve => {
                            return this.setTokenInStorage(resolve, true);
                          },
                          rejects => {
                            return Preferences.clear()
                              .then(_ => {
                                return this._router.navigate(['login'])
                              });
                          }
                        )
                    }
                    catch (error) {
                      alert(this._localizationService.translate('auth_refresh_token_error'));
                      return this._router.navigate(['login']);
                    }
                  } else {
                    return this._router.navigate(['login']);
                  }
                })
            }
          }
        } else return null;
      });
  }

  public async setTokenInStorage(resourceUrlResponse: any, refresh: boolean): Promise<any> {
    if (this.isDevice()) {
      if (refresh) {
        await Preferences.set({
          key: StorageKey[this.KEY_TOKEN],
          value: resourceUrlResponse.access_token
        });

        await Preferences.set({
          key: StorageKey[this.KEY_REFRESHTOKEN],
          value: resourceUrlResponse.refresh_token
        });

        return resourceUrlResponse.access_token;
      }
      else {
        await Preferences.set({
          key: StorageKey[this.KEY_TOKEN],
          value: resourceUrlResponse.access_token_response.access_token
        });

        await Preferences.set({
          key: StorageKey[this.KEY_REFRESHTOKEN],
          value: resourceUrlResponse.access_token_response.refresh_token
        });

        return resourceUrlResponse.access_token_response.access_token;
      }
    }
    else {
      await Preferences.set({
        key: StorageKey[this.KEY_TOKEN],
        value: resourceUrlResponse.access_token
      });
      return resourceUrlResponse.access_token;
    }
  }

  public async getSSODomain(): Promise<boolean> {
    return await new Promise<boolean>((resolve, reject) => {
      this.getTokenFromStorage()
        .then(token => {
          if (!token) {
            reject(this._localizationService.translate('auth_service_get_token_error'));
            return;
          }
          const decodedToken = this._jwtHelper.decodeToken(token);
          resolve(decodedToken.isSSODomain);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public async getUserRoles(): Promise<string[]> {
    return await new Promise<string[]>((resolve, reject) => {
      this.getTokenFromStorage()
        .then(token => {
          if (!token) {
            reject(this._localizationService.translate('auth_service_get_token_error'));
            return;
          }
          const decodedToken = this._jwtHelper.decodeToken(token);
          let roles: string[] = [];
          if (decodedToken) {
            let modules = (JSON.parse(decodedToken.extension_role));

            if (modules) {
              modules.forEach((m: any) => {
                const objs = roles.filter(r => r == m.RoleName)
                if (!(objs && objs.length > 0)) {
                  roles.push(m.RoleName);
                }
              });
            }
          }

          resolve(roles);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async isAuthenticated(): Promise<boolean> {
    const item = await Preferences.get({ key: this.KEY });
    const token = await Preferences.get({ key: this.KEY_TOKEN });

    if (
      token == null ||
      token.value == null ||
      !this.isTokenValid(token.value) ||
      item.value == null) {
      return false;
    }

    const itemValue = JSON.parse(item.value);
    const itemValueArr = Array.isArray(itemValue.companies) ? itemValue.companies : (itemValue.companies ? [itemValue.companies] : []);

    for (let i = 0; i < itemValueArr.length; i++) {
      const company = itemValueArr[i];
      if (company.companyType === CompanyType.SELF) {
        this.setResourceAdminState(true);
        break;
      }
    }

    if (item.value != null) {
      return true;
    }
    return false;
  }

  getResourceAdminState() {
    return this.isResourceAdminState.asObservable();
  }

  setResourceAdminState(state: boolean) {
    this.isResourceAdminState.next(state);
  }
  async handleCallBack(url: string): Promise<any> {
    const urlParamObj = WebUtils.getUrlParams(url);
    if (urlParamObj && urlParamObj['access_token'] != null && urlParamObj['state'] != null) {
      const token = await this.setTokenInStorage(urlParamObj, false) as string;
      const validUser = this.isTokenValid(token);
      return { policy: urlParamObj['state'], azureId: (validUser) ? this.getAzureObjectIdFromToken(token) : '' };
    }
    else if (urlParamObj && urlParamObj['error'] != null && urlParamObj['state'] == environment.b2cGoogleSignUpOrSignin) {
      alert(this._localizationService.translate('loginuser_signin_with_google_deactivated_user_error'));
    }
    else
      return new Promise<any>((_, reject) => {
        reject('Oauth parameters are not present in return url');
      });
  }

  public async loginToAzureB2C(userEmail: string, policy: string, vt: string): Promise<string> {
    return this.getTokenFromStorage().then(async token => {
      if (token && token != 'undefined') {
        const validUser = this.isTokenValid(token);
        if (validUser) {
          return this.getAzureObjectIdFromToken(token);
        }
      }
      this.oauth2Options.authorizationBaseUrl = environment.authB2C.authorizationBaseUrl.replace('{azure_policy}', policy);
      this.oauth2Options.accessTokenEndpoint = environment.authB2C.accessTokenEndpoint.replace('{azure_policy}', policy);
      if (policy == environment.b2cSignUpPolicy) {
        this.oauth2Options.additionalParameters = { 'vt': vt };
        this.oauth2Options.state = environment.b2cSignUpPolicy;
      } else {
        this.oauth2Options.additionalParameters = { 'login_hint': userEmail };
        this.oauth2Options.state = environment.b2cSignInPolicy;
      }
      if (this._utilsService.isMobileAndTablet()) {
        const resourceUrlResponse = await GenericOAuth2.authenticate(this.oauth2Options);

        if (resourceUrlResponse.error) {
          throw new Error(`${resourceUrlResponse.error} - ${resourceUrlResponse.error_description}`);
        }
        const token = await this.setTokenInStorage(resourceUrlResponse, false) as string;
        const validUser = this.isTokenValid(token);
        return (validUser) ? this.getAzureObjectIdFromToken(token) : '';
      }
      else {
        var result = await GenericOAuth2.authenticate(this.oauth2Options);
        return result;
      }

    });
  }

  public async loginWithApple() {
    this.oauth2Options.authorizationBaseUrl = environment.authB2C.authorizationBaseUrl.replace('{azure_policy}', environment.b2cSignInWithApplePolicy);
    this.oauth2Options.accessTokenEndpoint = environment.authB2C.accessTokenEndpoint.replace('{azure_policy}', environment.b2cSignInWithApplePolicy);
    this.oauth2Options.state = environment.b2cSignInWithApplePolicy;

    var result = await GenericOAuth2.authenticate(this.oauth2Options);
    return result;
  }

  public async loginUsingGoogle(): Promise<string> {
    try {
      this.oauth2Options.authorizationBaseUrl = environment.authB2C.authorizationBaseUrl.replace('{azure_policy}', environment.b2cGoogleSignUpOrSignin);
      this.oauth2Options.accessTokenEndpoint = environment.authB2C.accessTokenEndpoint.replace('{azure_policy}', environment.b2cGoogleSignUpOrSignin);
      this.oauth2Options.state = environment.b2cGoogleSignUpOrSignin;

      if (await this._utilsService.isMobileAndTablet()) {
        const resourceUrlResponse = await GenericOAuth2.authenticate(this.oauth2Options);

        if (resourceUrlResponse.error) {
          throw new Error(`${resourceUrlResponse.error} - ${resourceUrlResponse.error_description}`);
        }
        
        const token = await this.setTokenInStorage(resourceUrlResponse, false) as string;
        const validUser = this.isTokenValid(token);
        return (validUser) ? this.getAzureObjectIdFromToken(token) : '';
      }
      return await GenericOAuth2.authenticate(this.oauth2Options);
    } catch (error) {
      alert(this._localizationService.translate('loginuser_signin_with_google_deactivated_user_error'));
    }
    return '';
  }

  async logout(): Promise<void> {
    const token = await this.getTokenFromStorage();
    let decodedToken = this._jwtHelper.decodeToken(token);
    let isSSODomain: boolean = false;
    if (decodedToken) {
      isSSODomain = decodedToken.isSSODomain;
      let logOutUrl = "";
      let url = "";

      await this._userService.clearOutOfRangeSettings();

      if (isSSODomain) {
        this._referenceService.getUserEmailDomain()
          .subscribe({
            next: (userDomain) => {
              if (userDomain) {
                url = `${userDomain.logoutURL}${this.getRedirectUri()}`;
                this.logoutUser(token, url, isSSODomain);
              }
              else {
                alert(this._localizationService.translate('auth_get_user_domain_not_found'));
              }
            },
            error: (reject) => {
              alert(this._localizationService.translate('auth_get_user_domain_error'));
            }
          });
      }
      else {
        logOutUrl = environment.authB2C.signOutRequestUrl.replace('{azure_policy}', environment.b2cSignInPolicy);
        url = `${logOutUrl}?post_logout_redirect_uri=${this.getRedirectUri()}`;
        await this.logoutUser(token, url, isSSODomain);
      }
    }
  }

  private async logoutUser(token: string, url: string, isSSODomain: boolean) {
    let lang = await this._localizationService.get();
    let locale = await this._localizationService.getAzureLocale();
    await Preferences.clear();
    await this._localizationService.set(lang);
    await this._localizationService.setAzureLocale(locale);

    await GenericOAuth2.logout(this.oauth2Options, token);

    if (this._utilsService.isMobileAndTablet()) {
      Browser.addListener('browserPageLoaded', async () => {
        Browser.removeAllListeners().then(s => { });
        await Browser.close();

        await this._router.navigate(['login']);
        this.reloadPage();
      });
      await Browser.open({ url });
    } else {
      this._browserService.openExternalBroswer(url);
    }
  }

  private isTokenValid(token: string | null): boolean {
    return !!token && !this._jwtHelper.isTokenExpired(token);
  }

  private getAzureObjectIdFromToken(token: string | null): string {
    if (token) {
      const decodedToken = this._jwtHelper.decodeToken(token);
      return decodedToken.sub;
    }
    return "";
  }

  public async resetPassword(): Promise<void> {
    const url = `${environment.authB2C.resetPasswordUrl}&client_id=${environment.authB2C.appId}&nonce=defaultNonce&redirect_uri=${this.appendRedirectPath(this.document.location.origin)}/userprofile&scope=openid&response_type=id_token`;
    if (this.isDevice()) {

      this.oauth2Options.appId = environment.authB2C.appId;
      this.oauth2Options.scope = "openid";
      this.oauth2Options.state = environment.b2cForgotPasswordPolicy;
      this.oauth2Options.authorizationBaseUrl = environment.authB2C.authorizationBaseUrl.replace('{azure_policy}', environment.b2cForgotPasswordPolicy);
      let androidOption: AndroidOptions = {
        responseType: "id_token",
        redirectUrl: 'cityonsite://userprofile'
      };
      let iosOption: IosOptions = {
        pkceEnabled: true,
        responseType: "id_token",
        redirectUrl: 'cityonsite://userprofile'
      }
      this.oauth2Options.android = androidOption;
      this.oauth2Options.ios = iosOption;
      const resourceUrlResponse = await GenericOAuth2.authenticate(this.oauth2Options);

    }
    else {
      return this._browserService.openExternalBroswer(url);
    }
  }

  public async updateUserDetails(currentUser: User): Promise<void> {
    const email = encodeURIComponent(currentUser.emailAddress);
    const url = `${environment.authB2C.editProfileUrl}&client_id=${environment.authB2C.appId}&nonce=defaultNonce&redirect_uri=${this.appendRedirectPath(this.document.location.origin)}/userprofile&scope=openid&response_type=id_token&email=${email}`;
    if (this.isDevice()) {
      this.oauth2Options.appId = environment.authB2C.appId;
      this.oauth2Options.scope = "openid";
      this.oauth2Options.state = environment.b2cEditProfilePolicy;
      this.oauth2Options.authorizationBaseUrl = environment.authB2C.authorizationBaseUrl.replace('{azure_policy}', environment.b2cEditProfilePolicy);
      this.oauth2Options.additionalParameters = { 'email': currentUser.emailAddress };
      let androidOption: AndroidOptions = {
        responseType: "id_token",
        redirectUrl: 'cityonsite://userprofile'
      };
      let iosOption: IosOptions = {
        pkceEnabled: true,
        responseType: "id_token",
        redirectUrl: 'cityonsite://userprofile'
      }
      this.oauth2Options.android = androidOption;
      this.oauth2Options.ios = iosOption;
      const resourceUrlResponse = await GenericOAuth2.authenticate(this.oauth2Options);

    }
    else {
      return this._browserService.openExternalBroswer(url);
    }
  }

  public async deactivateUser(currentUser: User): Promise<void> {
    const email = encodeURIComponent(currentUser.emailAddress);
    const url = `${environment.authB2C.deactivateProfileUrl}&client_id=${environment.authB2C.appId}&nonce=defaultNonce&redirect_uri=${this.document.location.origin}/logout/true&scope=openid&response_type=id_token&email=${email}&prompt=login`;

    if (this.isDevice()) {
      this.oauth2Options.authorizationBaseUrl = environment.authB2C.authorizationBaseUrl.replace('{azure_policy}', environment.b2cDeactivateProfilePolicy);
      this.oauth2Options.accessTokenEndpoint = environment.authB2C.accessTokenEndpoint.replace('{azure_policy}', environment.b2cDeactivateProfilePolicy);
      this.oauth2Options.state = environment.b2cDeactivateProfilePolicy;
      this.oauth2Options.additionalParameters = { 'login_hint': currentUser.emailAddress };

      let androidOption: AndroidOptions = {
        responseType: "code",
        redirectUrl: 'cityonsite://logout/true'
      };
      let iosOption: IosOptions = {
        pkceEnabled: true,
        responseType: "code",
        redirectUrl: 'cityonsite://logout/true'
      }
      this.oauth2Options.android = androidOption;
      this.oauth2Options.ios = iosOption;
      try {
        const resourceUrlResponse = await GenericOAuth2.authenticate(this.oauth2Options);
        if (resourceUrlResponse.error) {
          throw new Error(`${resourceUrlResponse.error} - ${resourceUrlResponse.error_description}`);
        }
      }
      catch (error) {
        throw new Error(`${error}`);
      }
      return;
    }
    else {
      return this._browserService.openExternalBroswer(url);
    }
  }

  getRedirectUri(): string {
    return this.isDevice() ? 'cityonsite://auth' : this.appendRedirectPath(this.document.location.origin);
  }

  private isDevice(): boolean {
    return this._utilsService.isMobileAndTablet();
  }

  async clearLocalStorage() {
    await Preferences.clear();
  }

  async clearToken() {
    await Preferences.remove({ key: StorageKey[this.KEY_TOKEN] });
  }

  private appendRedirectPath(url: string): string {
    return this._utilsService.isNotAndroidOrIOS() ? url : url + '/redirect';
  }

  reloadPage() {
    window.location.reload();
  }
}
<div class="dialog-container">
    <h1>{{ 'site_management_edit_header' | translate }}</h1>
    <div class="divider"></div>
    <mat-divider class="table-divider-header"></mat-divider>
    <div class="responsive-table-container">
      <table
        mat-table
        data-testid="site_management_list_top_table"
        id="site_management_list_top_table"
        [dataSource]="filteredSites"
        multiTemplateDataRows>
        <ng-container matColumnDef="Name">
          <th
            mat-header-cell
            *matHeaderCellDef
            fxFlex="30"
            fxLayoutAlign="start center">
            {{ 'site_management_table_site_name_header' | translate }}
          </th>
          <td
            mat-cell
            class="color-dark-blue"
            *matCellDef="let site"
            fxFlex="30"
            fxLayoutAlign="start center">
            <span class="ellipsis">{{ site.site.name }}</span>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="Code">
          <th
            mat-header-cell
            *matHeaderCellDef
            fxFlex="15"
            fxLayoutAlign="start center">
            {{ 'site_management_table_site_code_header' | translate }}
          </th>
          <td
            mat-cell
            class="color-dark-blue"
            *matCellDef="let site"
            fxFlex="15"
            fxLayoutAlign="start center">
            <span class="ellipsis">{{ site.site.code }}</span>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="Id">
          <th
            mat-header-cell
            *matHeaderCellDef
            fxFlex="20"
            fxLayoutAlign="start center">
            {{ 'site_management_table_site_id_header' | translate }}
          </th>
          <td
            mat-cell
            class="color-dark-blue"
            *matCellDef="let site"
            fxFlex="20"
            fxLayoutAlign="start center">
            <span class="ellipsis">{{ site.site.id }}</span>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="QR">
          <th
            mat-header-cell
            *matHeaderCellDef
            fxFlex="15"
            fxLayoutAlign="center center">
            {{ 'site_management_table_site_qr_header' | translate }}
          </th>
          <td
            mat-cell
            class="color-dark-blue"
            *matCellDef="let site"
            fxFlex="15"
            fxLayoutAlign="center center">
            <qrcode
              [qrdata]="site.qrCodeLink"
              [width]="100"
              [errorCorrectionLevel]="'M'"></qrcode>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="QR Code Link">
          <th
            mat-header-cell
            *matHeaderCellDef
            fxFlex="30"
            fxLayoutAlign="start center">
            {{ 'site_management_table_site_qr_code_link_header' | translate }}
          </th>
          <td
            mat-cell
            class="color-dark-blue"
            *matCellDef="let site"
            fxFlex="30"
            fxLayoutAlign="start center">
            <span class="ellipsis">{{ site.qrCodeLink }}</span>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedSiteColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedSiteColumns;"
          class="element-row qr-row">
        </tr>
      </table>
    </div>
      

    <div class="divider"></div>

    <button 
        id="site_management_edit_cancel"
        data-testid="site_management_edit_cancel"
        mat-stroked-button class="color-city-blue btn-border-color"    
        (click)="dismissDialog()">
        {{ 'site_management_edit_cancel_button' | translate }}
  </button>   
</div>
<app-loader></app-loader>
<div [ngClass]="mainClass">

  <mat-toolbar *ngIf="appNavigationModel?.enableBackButton" class="bg-white">
      <button mat-icon-button 
        id="btn_backbutton" data-testid="btn_backbutton"
        (click)="back()">
        <mat-icon class="color-city-blue">
          keyboard_backspace
        </mat-icon>
      </button>
  </mat-toolbar>
  <mat-toolbar *ngIf="appNavigationModel?.enableMenu">
    <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()" id="btn_toggle">
        <mat-icon class="color-city-blue" *ngIf="!sidenav.opened">
            menu
        </mat-icon>
        <mat-icon class="color-city-blue"  *ngIf="sidenav.opened">
            close
        </mat-icon>
    </button>
  </mat-toolbar>
  <mat-sidenav-container [ngClass]="matSizeNavHeightClass">
    <mat-sidenav #sidenav="matSidenav">
      <button mat-button class="menu-button" 
        id="btn_profile" data-testid="btn_profile"
        (click)="userProfileOnClick()">
        <mat-icon class="menu-icon color-city-blue">person_outline</mat-icon>
        <span class="color-dark-blue">{{'lbl_menu_profile' | translate}}</span>
      </button>
      <button mat-button class="menu-button" 
        id="btn_documents" data-testid="btn_documents"
        (click)="userDocumentsOnClick()">
        <mat-icon class="menu-icon color-city-blue">note_add_outline</mat-icon>
        <span class="color-dark-blue">{{'lbl_menu_documents' | translate}}</span>
      </button>
      <button mat-button class="menu-button" 
        id="btn_privacy_policy" data-testid="btn_privacy_policy"
        (click)="privacyPolicyOnClick()">
        <mat-icon class="menu-icon color-city-blue">menu_book_outline</mat-icon>
        <span class="color-dark-blue">{{'lbl_menu_privacy_policy' | translate}}</span>
      </button>
      <button mat-button class="menu-button" 
        id="btn_term_condition" data-testid="btn_term_condition"
        (click)="termsConditionOnClick()">
        <mat-icon class="menu-icon color-city-blue">privacy_tips_outline</mat-icon>
        <span class="color-dark-blue">{{'lbl_menu_term_condition' | translate}}</span>
      </button>
      <button mat-button class="menu-button" 
      id="btn_term_condition" data-testid="btn_term_condition"
      (click)="needHelpOnClick()">
      <mat-icon class="menu-icon color-city-blue">help</mat-icon>
      <span class="color-dark-blue">{{'lbl_menu_need_help' | translate}}</span>
    </button>
      <div *ngIf="isResourceAdmin">
        <button mat-button class="menu-button" 
          id="btn_workbench" data-testid="btn_workbench"
          (click)="workBenchOnClick()">
          <mat-icon class="menu-icon color-city-blue">view_module</mat-icon>
          <span class="color-dark-blue">{{'lbl_menu_workbench' | translate}}</span>
        </button>
        <button *ngIf="!isMobileAndTablet" mat-button class="menu-button" 
          id="btn_user-management" data-testid="btn_user-management"
          (click)="userManagementOnClick()">
          <mat-icon class="menu-icon color-city-blue">people_outline</mat-icon>
          <span class="color-dark-blue">{{'lbl_menu_user-management' | translate}}</span>
        </button>
      </div>
      <div *ngIf="isCityAdmin && !isMobileAndTablet">
        <button mat-button class="menu-button" 
          id="btn_admin-management" data-testid="btn_admin-management"
          (click)="adminManagementOnClick()">
          <mat-icon class="menu-icon color-city-blue">supervised_user_circle</mat-icon>
          <span class="color-dark-blue">{{'lbl_menu_admin-management' | translate}}</span>
        </button>
        <button mat-button class="menu-button" 
          id="btn_site-management" data-testid="btn_site-management"
          (click)="siteManagementOnClick()">
          <mat-icon class="menu-icon color-city-blue">assignment</mat-icon>
          <span class="color-dark-blue">{{'lbl_menu_site-management' | translate}}</span>
        </button>
        <button mat-button class="menu-button" 
        id="btn_visitorType-management" data-testid="btn_visitorType-management"
        (click)="visitorTypeManagementOnClick()">
        <mat-icon class="menu-icon color-city-blue">manage_accounts</mat-icon>
        <span class="color-dark-blue">{{'lbl_menu_visitor_type_management' | translate}}</span>
      </button>
      </div>
      <button mat-button class="menu-button" 
        id="btn_logout" data-testid="btn_logout"
        (click)="logOutOnClick()">
        <mat-icon class="menu-icon color-city-blue">logout_outline</mat-icon>
        <span class="color-dark-blue">{{'lbl_menu_logout' | translate}}</span>
      </button>        
      <div *ngIf="appEnvironment != 'prod' && appEnvironment != 'uat' && appEnvironment != 'demo'">
        <hr />
        <button mat-button class="menu-button">
          You are in {{appEnvironment}} env
        </button>
      </div>
      <hr />
      <button mat-button class="menu-button">
        {{'v' + version }}
      </button>
    </mat-sidenav>
    <mat-sidenav-content class="main-content color-dark-blue">
      <router-outlet *ngIf="isNetworkAvailabile"></router-outlet>
      <div *ngIf="!isNetworkAvailabile">
        <p>{{'error_lbl_no_connection' | translate}}</p>
        <input mat-raised-button id="btn_retry" data-testid="btn_retry" name="btn_retry" type="button" value="{{'error_btn_retry' | translate}}" (click)="initConnection()">
      </div>
    </mat-sidenav-content>    
  </mat-sidenav-container>
</div>
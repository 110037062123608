import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { Job } from 'src/app/models/job.model';
import { Module, SignInInfo, User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClosedownService } from 'src/app/services/closedown/closedown.service';
import { JobService } from 'src/app/services/job/job.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { SiteService } from 'src/app/services/site/site.service';
import { SiteApiService } from 'src/app/services/site/site.api.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilsService } from 'src/app/services/util/util-service';
import { LocalizationService } from '../internationalization/localization.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Site } from 'src/app/models/site.model';
import { FormService } from 'src/app/services/form/form.service';
import { CompanyType, FastLinkType, JobType, SignInMethod, UserType } from 'src/app/shared/enums';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { GeolocationService } from '@app/services/geolocation/geolocation.service';
import { NotificationPushService } from '@app/services/notification/notification.push.service';
import { Position } from '@capacitor/geolocation';
import { ConfigurationService } from '@app/services/configuration/configuration.service';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
    private unsubscriberBackBtn: Subject<void> = new Subject<void>();
    private latestLocation: Position | null = null;
    private _leftAlignment: string = ' left';
    private _centerAlignment: string = ' center';
    private _currentPage = 1;
    private _itemsPerLoad = 10;
    private _jobListStyleWeb: string = 'job-list-web';
    private _jobListStyleMobile: string = 'job-list-mobile';

    currentUser: User | undefined;
    withJob = false;
    fastLinkNearByMessage: string = '';
    jobs: Job[] = [];
    siteCode: string | undefined;
    userLat: any = null;
    userLong: any = null;
    currentSite: Site | undefined;
    modules: Module[] = [];
    workBench: string = 'WORKBENCH';
    inductionModuleEnable = false;
    resourceIds: string[] = [];
    modulesExist: boolean = false;
    withFastLink: boolean | undefined = undefined;
    showLoadingJobs: boolean = true;
    jobsToDisplay: Job[] = [];
    workBenchClicked: boolean = false;

    constructor(private _authService: AuthService,
        private _router: Router,
        private _loader: LoaderService,
        private _localizationService: LocalizationService,
        private _navigationService: NavigationService,
        private _jobService: JobService,
        private _userService: UserService,
        private _siteService: SiteService,
        private _siteApiService: SiteApiService,
        private _utilsService: UtilsService,
        private _closedownService: ClosedownService,
        private _formService: FormService,
        private _activatedRoute: ActivatedRoute,
        private _geolocationService: GeolocationService,
        private _notificationPushService: NotificationPushService,
        private _configurationService: ConfigurationService) {
        this._navigationService.onChange(true, false);
        this.initBrowserBackButton();
        this._configurationService.getJobListRenderBatchSize().then(size => {
            this._itemsPerLoad = size;
        });
    }

    async ngOnInit(): Promise<void> {
        this._loader.showPageLoading();
        await this.loadUserModules();
        
    }

    private async loadUserModules() {
        this._userService.getUserModules().subscribe({
            next: (m: Module[]) => {
                if (m) {
                    this._authService.setUserModule(m);
                }

                this.loadPageData();
            },
            error: (e: string) => {
                alert(this._localizationService.translate('closedown_error_usermodule') + e);
            }
        });
    }

    private async loadPageData(){
        this._authService.getTokenFromStorage().then(bearer => {
            this._closedownService.setBearerToken(bearer);
        });
        this.workBenchClicked = this._activatedRoute.snapshot.paramMap.get('bypass')?.toLowerCase() == 'true';
        this._closedownService.setByPass(this.workBenchClicked);

        this.inductionModuleEnable = await this._configurationService.getInductionModuleEnable();
        if (this.inductionModuleEnable) {
            await this.loadGeolocation();
            await this._notificationPushService.registerNotifications();
        }

        //for remote fix.
        let checkWorkbenchModule = (await this._authService.getUserModule())?.find(s=> s.productName.toUpperCase() == this._closedownService.workBench);
        this.modulesExist  = checkWorkbenchModule != null && checkWorkbenchModule != undefined;

        var processFlow = await this._closedownService.getProcessFlow();
        if (processFlow.siteCode != '' && processFlow.token) {
            this.siteCode = processFlow.siteCode;
            this._closedownService.setWithFastLink(true);
            this.fastLinkNearByMessage = '';
        } else {
            this._closedownService.setWithFastLink(false);
        }
        this.withFastLink = this._closedownService.getWithFastLink();
        if (this.inductionModuleEnable) {
            await this.getGeoLocation();
        }
        this._authService.getUser().then(user => {
            this.currentUser = user;
            
            this._closedownService.setCurrentUser(user).then(c => {
                if (this.inductionModuleEnable && !(this.isResourceAdmin() && !this._utilsService.isMobileAndTablet())) {
                    this._userService.getSignInInfo().subscribe({
                        next: (signinInfo) => {
                            if (signinInfo) {
                                let signInInfoSetInLocal : SignInInfo = {
                                    signInSignOutId : signinInfo.id,
                                    siteId : signinInfo.siteId,
                                    complianceCompany : signinInfo.complianceCompany
                                }
                                this._userService.setSignIn(signInInfoSetInLocal);
                                alert(this._localizationService.translate("signin_error_sign_in_info_exist"));
                                this._siteService.setReasonForVisit(signinInfo.reasonForVisit);
                            
                                this._siteApiService.getSiteBySiteId(signinInfo.siteId).subscribe({
                                    next: (siteInfo) => {
                                        if (siteInfo) {
                                            this._siteService.setSignedInSite(siteInfo);
                                            this._router.navigate(['siteinductioncompleted']);
                                        }
                                    },
                                    error: (error) => {
                                        if (error.error && error.error.errorCode) {
                                            alert(this._localizationService.translate(error.error.errorCode));
                                        }
                                        else {
                                            alert(this._localizationService.translate("signin_error_get_sites"));
                                        }
                                        this._loader.hidePageLoading();
                                        this._authService.logout().then(async _ => { });
                                    }
                                })

                            }
                            else {
                                this.getUserJobs();
                            }
                        },
                        error: (reject) => {
                            this._loader.hidePageLoading();
                            this._authService.logout().then(async _ => { });
                        }
                    });
                }
                else {
                    this.reloadWhenBrowserClose();
                }
                this._loader.hidePageLoading();
            }).catch(() => {
                this._loader.hidePageLoading();
            });
        }).catch().finally();
    }

    private async loadGeolocation() :  Promise<void>{
        this.latestLocation = this._geolocationService.getLatestLocation();
        if (this.latestLocation === null) {
            this._loader.show();
            this.latestLocation = await this._geolocationService.setLatestLocation();
            this._loader.hide();
        }
    }

    private async getGeoLocation() {
        if (this.latestLocation) {
            this.userLat = this.latestLocation.coords.latitude;
            this.userLong = this.latestLocation.coords.longitude;
            if (this.siteCode) {
                this._siteApiService.getNearbySiteBySiteCode(this.siteCode, this.userLat, this.userLong)
                    .subscribe({
                        next: (site) => {
                            if (site) {
                                this.currentSite = site;
                                this._siteService.setSignedInSite(site);
                            }
                            else {
                                this.fastLinkNearByMessage = this._localizationService.translate("signin_out_of_geofencing_range");
                            }
                        },
                        error: (error) => {
                            if (error.error && error.error.errorCode) {
                                alert(this._localizationService.translate(error.error.errorCode));
                            }
                            else {
                                alert(this._localizationService.translate("signin_error_no_site_found").replace('{site_code}', (this.siteCode) ? this.siteCode : ''));
                            }
                            this._authService.logout().then(async _ => { });
                        }
                    });
            }
        }
        else {
            this.fastLinkNearByMessage = this._localizationService.translate("signin_device_location_turn_off");
        }
    }

    private async getUserJobs(): Promise<void> {
        if (this._closedownService.getWithFastLink()) {
            var processFlow = await this._closedownService.getProcessFlow();
            let jobId = processFlow.jobId;
            let subContractorId = processFlow.subContResourceId;
            if (jobId && subContractorId != undefined) {
                this._jobService.getJobsByJobNSubContractorId(jobId, subContractorId).subscribe({
                    next: (jobs: Job[]) => {
                        if (jobs && jobs.length > 0 && this.currentUser) {
                            this.orderJobsByDistance(jobs);
                        }
                        else{
                            alert(this._localizationService.translate('reasonforvisit_no_fastlink_job_found'));
                            this._closedownService.removeProcessFlow();
                            this.reloadPage();
                        }
                    },
                    error: (error) => {
                        if (error.error && error.error.errorCode) {
                            alert(this._localizationService.translate(error.error.errorCode));
                        }
                        else {
                            alert(this._localizationService.translate("signin_error_get_job"));
                        }
                        this.showLoadingJobs = false;
                    }
                })
            }
            else {
                this.showLoadingJobs = false;
            }
        }
        else {
            let userCompanies: string[] = [];
            if (this.currentUser) {
                if (this.currentUser.companies) {
                    this.currentUser.companies.forEach(company => {
                        if (company.companyType.toUpperCase() != CompanyType.SUBCON) {
                            userCompanies.push(company.referenceNumber);
                        }
                    });
                }
                this._jobService.getJobsByCompanyRefNumberWithSkipHeader(undefined).subscribe({
                    next: (jobs: Job[]) => {
                        if (jobs && jobs.length > 0 && this.currentUser) {
                            this.orderJobsByDistance(jobs);
                        }
                        else{
                            this.showLoadingJobs = false;
                        }
                    },
                    error: (err) => {
                        if (err.error && err.error.errorCode) {
                            alert(this._localizationService.translate(err.error.errorCode));
                        }
                        else {
                            alert(this._localizationService.translate("signin_error_get_job"));
                        }
                        this.showLoadingJobs = false;
                    }
                })
            }
            else {
                this.showLoadingJobs = false;
            }
        }
    }

    qrCodeSignInOnClick() {
        this._router.navigate(['qrsignin']);
    }

    siteSignInOnClick() {
        this._userService.setSignInMethod(SignInMethod.GPS);
        this._router.navigate(['sitesignin', 1]);
    }

    welcome(): string {
        return this._localizationService.translate("signin_lbl_welcome",
            {
                name: `${this.currentUser?.firstName || ''} ${this.currentUser?.lastName || ''}`
            });
    }

    welcomeDesc(): string {
        var lblWelcome = "";
        if (this._closedownService.getWithFastLink() && this.siteCode) {
            lblWelcome = this._localizationService.translate("signin_lbl_welcome_with_link", { site_name: `${this.siteCode || ''}` });
        } else {
            lblWelcome = this._localizationService.translate("signin_lbl_welcome_no_link");
        }

        return lblWelcome;
    }

    initBrowserBackButton() {
        history.pushState(null, '');
        fromEvent(window, 'popstate').pipe(takeUntil(this.unsubscriberBackBtn)).subscribe((_) => {
            history.pushState(null, '');

            if (confirm(this._localizationService.translate('appcomponent_dialog_logout'))) {
                this._authService.logout().then(r => {
                    this._authService.logout().then(async _ => { });
                });
            }
        });
    }

    isExternalUser(): boolean {
        return this._closedownService.getIsExternalUser();
    }

    isResourceAdminUserAndBrowser(): boolean | undefined {
        return (this._closedownService.getIsResourceAdminUser() && !this._utilsService.isMobileAndTablet());
    }

    isResourceAdmin(): boolean {
        let isAdmin : boolean = false;
        if (this.currentUser) {
            if (this.currentUser.companies.length > 0) {
                this.currentUser.companies.forEach(company => {
                    if (company.companyType.toUpperCase() == CompanyType.SELF){
                        isAdmin =  true;
                    }
                });
            }
        }
        return isAdmin;
    }

    byPass(): boolean {
        return this._closedownService.getByPass();
    }
    ngOnDestroy(): void {
        this.unsubscriberBackBtn.next();
        this.unsubscriberBackBtn.complete();
    }

    ContinueClick() {
        if (this.currentSite) {
            if (this.currentSite.isInductionRequired) {
                this._formService.getByBrandOrSiteId(this.currentSite.brand.id, this.currentSite.id).subscribe({
                    next: (form) => {
                        if (form) {
                            this._formService.setInductionConfig(form).then(r => {
                                this._userService.setSignInMethod(SignInMethod.FASTLINK);
                                this._router.navigate(['induction'], {
                                    queryParams: {
                                        inductionSequence: 1
                                    }
                                });
                            });
                        }
                        else {
                            alert(this._localizationService.translate("signin_dialog_siteinduction_notexist"));
                            this._closedownService.removeProcessFlow().then(pf => {
                                this._siteService.removeSignedInSite().then(r => {
                                    this._router.navigate(['signin']);
                                  });
                            });
                        }
                    },
                    error: e => {
                        if (e.error && e.error.errorCode) {
                            alert(this._localizationService.translate(e.error.errorCode));
                        }
                        else {
                            alert(this._localizationService.translate("signin_dialog_siteinduction_notexist"));
                        }
                        this._siteService.removeSignedInSite();
                        this._router.navigate(['signin']);
                    }
                });
            } else {
                this.workBenchClick();
            }
        }
    }

    RemoteFixClick() {
        this._closedownService.directToClosedown(true);
        this.reloadWhenBrowserClose();
    }

    workBenchClick() {
        this.workBenchClicked = true;
        this._closedownService.directToClosedown(false,undefined , this._closedownService.getSignInBackUrl());
        this.reloadWhenBrowserClose();
    }

    reloadWhenBrowserClose(){
        if(this._utilsService.isMobileAndTablet()){
            Browser.addListener('browserFinished',() =>  {
                Browser.removeAllListeners().then(s=>{});
                this.reloadPage();
            });
        }
    }

    isReactiveJob(job : Job){
       return (job.jobType == JobType.REACTIVE_JOB) ? true : false;
    }

    isPPMJob(job : Job){
        return (job.jobType == JobType.PPM_JOB) ? true : false;
     }

    reloadPage() {
        this._router.navigate(['signin']);
    }

    loadingJobs(): string {
        return this._localizationService.translate("signin_lbl_loading_jobs");
    }

    /**
     * Helper method for determining the alignment of the Loading Jobs spinner.
     * @returns 
     *      The value for fxLayoutAlign of the Loading Jobs spinner.
     */
    loadingJobsAlignment(): string {
        // Default to left alignment
        let alignment = this._leftAlignment;

        if(this._utilsService.isMobileDevice()){
            alignment = this._centerAlignment;
        }

        return alignment;
    }

    /**
     * Determines and appends the next batch of jobs to the job list display.
     */
    appendJobs(): void{
        const startIndex = (this._currentPage - 1) * this._itemsPerLoad;
        const endIndex = startIndex + this._itemsPerLoad;
        let jobsToAppend: Job[] = [];
        jobsToAppend = this.jobs.slice(startIndex, endIndex);

        this.jobsToDisplay = [...this.jobsToDisplay,...jobsToAppend];
    }

    onScroll(): void{
        this._currentPage++;
        this.appendJobs();
    }

    /**
     * Determines the style to be applied to the job list display depending on the device type (web or mobile).
     * @returns
     *      CSS class name.
     */
    jobListClass(): string {
        let styleToApply: string = this._jobListStyleWeb;

        if(this._utilsService.isMobileDevice()){
            styleToApply = this._jobListStyleMobile;
        }

        return styleToApply;
    }

    orderJobsByDistance(jobs : Job[]) {
        if(jobs && jobs.length > 0 && !this.isResourceAdminUserAndBrowser() && this.userLat && this.userLong){
            let siteIds = [...new Set(jobs.map(job => job.siteId))];
  
            this._siteApiService.postOrderSiteLocationWithLatAndLong(siteIds, this.userLat, this.userLong).subscribe({
                next: (sites: Site[]) => {
                    if (sites && sites.length > 0 && this.currentUser) {
                        sites.forEach(site => {
                            jobs.filter(job => job.siteId === site.id).forEach(job => {
                                this.jobs.push(job);
                            })
                        });
                        this.withJob = true;
                        this._closedownService.setUserJobs(this.jobs);
                        this.currentUser.withJob = true;
                        this._authService.setUser(this.currentUser);
                    }                
                },
                error: (error) => {
                    if (error.error && error.error.errorCode) {
                        alert(this._localizationService.translate(error.error.errorCode));
                    }
                    else {
                        alert(this._localizationService.translate("signin_error_order_site_location"));
                    }
                    this.showLoadingJobs = false;
                },
                complete: () => {
                    this.showLoadingJobs = false; 
    
                    if(this.jobs.length > 0)
                    {
                        this.appendJobs();
                    }  
                }
            })
        }else{
            this.showLoadingJobs = false; 
        }
    }
}
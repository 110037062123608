<mat-toolbar class="bg-white mat-toolbar-override">
    <button mat-icon-button (click)="back()" id="btn_signedinvisitorback">
        <mat-icon color="primary">
            keyboard_backspace
        </mat-icon>
    </button>
</mat-toolbar> 

<div *ngIf="hasError">
    <div class="alert alert-danger">
        <p>
            {{errorMessage}}
        </p>
    </div>
</div>

<div fxLayout="row">
    <div fxLayout="column">
        <span class="font-size-h1 color-city-blue">{{ ('signedin_lbl_thank_you' | translate).replace("{name}", fullName) }}</span>
        <span class="font-size-body-text color-dark-blue">{{ ('signedinvisitor_lbl_signed_in' | translate).replace("{site_name}", site?.name) }}</span>
    </div>
</div>

<div class="divider"></div>
<div fxLayout="row" *ngFor="let type of visitorTypes">
    <div fxLayout="column" fxFlex="100">
        <div class="divider"></div>
        <button mat-stroked-button id="btn_type{{type.id}}" (click)="visitorTypeClick(type)" [ngClass]="{'selected' : type.selected, 'unselected': !type.selected}">
            <span fxFlex="1 1 auto" fxFlexOrder="1">{{ type.displayDescription }}</span>
            <mat-icon class="maticon" *ngIf="type.selected" fxFlex="0 0 auto" fxFlexOrder="2">task_alt</mat-icon>
        </button>
    </div>
</div>

<div class="divider"></div>
<div fxLayout="row">
    <div fxLayout="column" fxFlex="100%">
        <button mat-raised-button (click)="continueOnClick()" id="btn_continuesignedin">
            {{ 'signedin_lbl_continue' | translate }}
        </button>
    </div>
</div>

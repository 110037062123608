import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { InductionConfig } from 'src/app/models/inductionconfig.model';
import { User } from 'src/app/models/user.model';
import { VisitorType } from 'src/app/models/visitortype.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormService } from 'src/app/services/form/form.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ReferenceService } from 'src/app/services/reference/reference.service';
import { SiteService } from 'src/app/services/site/site.service';
import { LocalizationService } from '../internationalization/localization.service';
import { Site } from '@app/models/site.model';
import { ClosedownService } from '@app/services/closedown/closedown.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-signed-in-visitor',
  templateUrl: './signed-in-visitor.component.html',
  styleUrls: ['./signed-in-visitor.component.scss']
})
export class SignedInVisitorComponent implements OnInit, OnDestroy {
  private unsubscriberBackBtn : Subject<void> = new Subject<void>();
  
  site : Site | undefined;
  fullName : string | undefined;
  currentUser : User | undefined;
  visitorTypes : VisitorType[] | undefined;
  localeId : string | undefined;
  inductionConfig : InductionConfig | undefined;
  hasError : boolean = false;
  errorMessage : string = "";

  constructor(private _localizationService : LocalizationService,
              private _siteService : SiteService,
              private _router : Router,
              private _authService : AuthService,
              private _referenceService : ReferenceService,
              private _formService : FormService,
              private _navigationService:NavigationService,
              private _closedownService : ClosedownService) { 
                
        this._navigationService.onChange(false, false);
        this.initBrowserBackButton();
  }

  ngOnInit(): void {
    this.localeId = environment.defaultLang.toLowerCase();;
    this._localizationService.get().then((l) => {
      if(l){
        this.localeId = l.toLowerCase();
      }
    });

    this._authService.getUser().then(
      user =>
      {
          this.currentUser = user;
          this.fullName = `${user.firstName} ${user.lastName}`;
          this.loadSite();
      });
  }

  loadSite(){
    this._siteService.getSignedInSite().then(store => {
      if(store){
        this.site = store;
        this.loadForm();
      }else{
        this._siteService.removeSignedInSite();
        this._router.navigate(['signin']);
      }
    },
    error => {
      alert(this._localizationService.translate('signinvisitor_brand_retrieval_failed'));
      this._siteService.removeSignedInSite();
      this._router.navigate(['signin']);
    });
  }

  loadForm(){
    this._formService.getInductionConfig().then(form => {
      if(form){
        this.inductionConfig = form;
        this.loadVisitorCategory();
      }else{
        alert(this._localizationService.translate('signinvisitor_induction_retrieval_failed'));
        this._siteService.removeSignedInSite();
        this._router.navigate(['signin']);
      }
    },
    error => {
      alert(this._localizationService.translate('signinvisitor_induction_retrieval_failed'));
      this._siteService.removeSignedInSite();
      this._router.navigate(['signin']);
    });
  }

  loadVisitorCategory(){
    if(this.site){
      this._referenceService.getVisitorTypes(this.site?.brand.id, true).subscribe({
        next: (type) => {
          if (type){
            this.visitorTypes = type;
            this.visitorTypes.forEach(type => {
               if (this.inductionConfig?.visitorType !== undefined){
                  type.selected = this.inductionConfig.visitorType == type.id;
                  this.inductionConfig.visitorType  = type.description;
                  this.inductionConfig.visitorId  = type.id!;
               }
               else{
                  type.selected = false;
               }
              
               type.displayDescription = type.translations!.find(s => s.languageCode.toLowerCase() ==  this.localeId)?.description ?? type.description;
            })
          }
          else{
            console.log("No visitor type retrieve");
            alert(this._localizationService.translate('signinvisitor_visitortype_retrieval_failed'));
          }
        },
        error: (error) => {
          console.log(this._localizationService.translate('signinvisitor_visitortype_retrieval_failed'), error);
          alert(this._localizationService.translate('signinvisitor_visitortype_retrieval_failed'));
        }
      });
    }else{
      alert(this._localizationService.translate('signinvisitor_brand_retrieval_failed'));
    }
  }

  back(){
    if(confirm(this._localizationService.translate("signedin_lbl_leave_store"))){
      this._siteService.removeVisitReasons();
      this._closedownService.removeProcessFlow().then(pf => {
        this._formService.removeInductionConfig().then(f =>{
          this._siteService.removeSignedInSite().then(r => {
            this._router.navigate(['signin']);
          });
        });
      });
    }
  }

  continueOnClick(){
    let selectOnce = false;
    if (this.visitorTypes){
      this.visitorTypes.forEach(type => {
         if (type.selected){
          if (this.inductionConfig){
            this.inductionConfig.visitorType = type.description;
            this.inductionConfig.visitorId = type.id!;
            if (!this.currentUser?.withJob) this.resetReasonsForVisit();
            this._formService.setInductionConfig(this.inductionConfig);
            selectOnce = true;
          }
         }
      });
    }
   
    if (selectOnce){
      this._router.navigate(['induction'], {
        queryParams :{
          inductionSequence : 1
      }});
    }
    else{
      this.errorMessage = this._localizationService.translate("signedinvisitor_selectatleast_visitorcategory");
      this.hasError = true;
    }

  }

  visitorTypeClick(item : any){
    this.errorMessage = "";
    this.hasError = false;
    if(!item.selected){
      if (this.visitorTypes){
        this.visitorTypes.forEach(type => {
          type.selected = false;
       })
      };
     item.selected = true;
    }
  }

  resetReasonsForVisit(){
    if (this.inductionConfig){
      let inductionConfigDetailsReasons = this.inductionConfig.inductionConfigDetails.find(i => i.type == "ReasonForVisit");
      if (inductionConfigDetailsReasons) inductionConfigDetailsReasons.answer = "";
    }
  }

  initBrowserBackButton(){
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(takeUntil(this.unsubscriberBackBtn)).subscribe((_) => {
      history.pushState(null, '');
      this.back();
    });
  }

  ngOnDestroy():void{
    this.unsubscriberBackBtn.next();
    this.unsubscriberBackBtn.complete();
  }

}

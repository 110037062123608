import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { SiteService } from 'src/app/services/site/site.service';
import { SiteApiService } from 'src/app/services/site/site.api.service';
import { UserService } from 'src/app/services/user/user.service';
import { ConfigurationKey, RegisterStatus } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';
import { ClosedownService } from '../../services/closedown/closedown.service';
import { LocalizationService } from '../internationalization/localization.service';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FileStorageService } from '@app/services/filestorage/filestorage.service';
import { BrowserService } from '@app/services/browser/browser.service';
import { UtilsService } from 'src/app/services/util/util-service';
import { LoaderService } from '@app/services/loader/loader.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { Language } from '@app/models/language.model';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})

export class LoginUserComponent implements OnInit {
  public loginUserFormGroup!: UntypedFormGroup;
  public siteId: string | undefined;
  public jobId: any;
  public token: any;
  public version = environment.appVersion;
  private inductionModuleEnable: boolean = false;
  private rapidGlobalModuleEnable: boolean = false;
  private profileInductionModuleEnable: boolean = false;
  isMutliLanguageEnable: boolean = false;
  language: string = environment.defaultLang;
  languages: Language[] = [];

  constructor(private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _localizationService: LocalizationService,
    private _authService: AuthService,
    private _siteApiService: SiteApiService,
    private _siteService: SiteService,
    private _navigationService: NavigationService,
    private _userService: UserService,
    public _dialog: MatDialog,
    private _closedownService: ClosedownService,
    private _fileService: FileStorageService,
    private _browserService: BrowserService,
    private _loader: LoaderService,
    private _utilsService: UtilsService,
    private _configurationService: ConfigurationService) {

    this._navigationService.onChange(false, false);
    this.siteId = this._activatedRoute.snapshot.paramMap.get('siteId')?.toLocaleLowerCase();
    this.jobId = this._activatedRoute.snapshot.paramMap.get('jobId');
    this._activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.loginUserFormGroup = this.initFormGroup();
  }

  ngOnInit() {
    if (!this._utilsService.isMobileAndTablet())
      history.pushState(null, '', window.location.origin);

    this.init();
  }

  async init() {
    this.language = await this._localizationService.get() ?? environment.defaultLang;

    this.isMutliLanguageEnable = await this._configurationService.getIsMultiLanguageEnable();
    if (this.isMutliLanguageEnable) {
      this._localizationService.getLanguages().subscribe({
        next: async (langs) => {
          this.languages = [...langs];
        }
      });
    }

    // Retrieve setting from local settings/API
    let shouldRetrieveFromAPI = await this._configurationService.shouldRetrieveFromAPI();

    if (shouldRetrieveFromAPI) {
      await this._configurationService.getAndSetLocalConfigurations(true);
    }

    this.inductionModuleEnable = await this._configurationService.getInductionModuleEnable();
    this.rapidGlobalModuleEnable = await this._configurationService.getProfileRapidGlobalRequired();
    this.profileInductionModuleEnable = await this._configurationService.getProfileInductionRequired();

    if (this.siteId) {
      await this._siteService.setQrSiteId(this.siteId);
    }
    if (this.token) {
      this._userService.verifyUser(this.token).subscribe({
        next: async (emailAddress) => {
          if (emailAddress && emailAddress.trim().length > 0) {
            this._authService.clearLocalStorage().then(
              async () => {
                await this.register(this.token);
              }
            )
          } else {
            alert(this._localizationService.translate('error_expiry_link'));
            this._router.navigate(['signin']);
          }
        },
        error: e => {
          this._router.navigate(['signin']);
        }
      });
    } else {
      this._authService.isAuthenticated()
        .then(async auth => {
          if (auth) {
            if (this.siteId && this.jobId && !Number.isNaN(this.jobId) && this.jobId > 0) {
              this.fastlinkRedirection(this.siteId, Number(this.jobId));
            } else {
              let siteId = await this._siteService.getQrSiteId();
              if (siteId != undefined && siteId != '') {
                this._router.navigate(['qrsignin']);
              } else {
                this._router.navigate(['signin']);
              }
            }
          } else {
            if (!await this._utilsService.isMobileAndTablet()) {
              this._authService.handleCallBack(window.location.origin.concat(this._router.url))
                .then(
                  result => {
                    if (result.policy === environment.b2cSignInPolicy || result.policy === environment.b2cSignInWithApplePolicy || result.policy === environment.b2cGoogleSignUpOrSignin) {
                      this.loginUser()
                    }
                    else {
                      this.registerUser();
                    }
                  },
                  reject => console.log(reject)
                )
                .catch(reason => {
                  console.log('Oauth2 callback error: %o', reason);
                });
            } else {
              const oauthError = localStorage.getItem('oauthError');
              if (oauthError) {
                alert(oauthError);
              }
            }
          }
        });
    }
  }

  fastlinkRedirection(siteId: string, jobId: number) {
    this._siteApiService.getNearbySiteBySiteId(11, 11, siteId).subscribe({
      next: (site) => {
        if (site) {
          this._siteService.setSignedInSite(site).then(s => {
            this._router.navigate(['signedin']);
          });
        } else {
          alert(this._localizationService.translate('loginuser_invalid_site'));
          this._router.navigate(['signin']);
        }
      },
      error: (error) => {
        if (error.error && error.error.errorCode) {
          alert(this._localizationService.translate(error.error.errorCode));
        }
        else {
          alert(this._localizationService.translate("loginuser_error_get_sites"));
        }
      }
    });
  }

  private initFormGroup(): UntypedFormGroup {
    const loginUserForm = this._formBuilder.group({
      emailAddress: new UntypedFormControl(undefined, [Validators.required, Validators.email])
    });
    return loginUserForm;
  }

  public async continue(): Promise<void> {
    const emailAddress = this.loginUserFormGroup.get('emailAddress');
    if (!environment.isDemo) {
      await this._authService.clearToken();
      await this._authService.loginToAzureB2C(emailAddress?.value, environment.b2cSignInPolicy, "");
      this.loginUser();
    }
    else {
      if (this.siteId && this.jobId && !Number.isNaN(this.jobId) && this.jobId > 0) {
        this._router.navigate(['password', this.loginUserFormGroup.get('emailAddress')?.value, this.siteId, this.jobId]);
      } else if (this.siteId) {
        this._router.navigate(['password', this.loginUserFormGroup.get('emailAddress')?.value, this.siteId]);
      } else {
        this._router.navigate(['password', this.loginUserFormGroup.get('emailAddress')?.value]);
      }
    }
  }

  public async signInWithApple() {
    await this._authService.clearToken();
    await this._authService.loginWithApple();
  }

  public async loginWithGoogle(): Promise<void> {
    await this._authService.clearToken();
    var result = await this._authService.loginUsingGoogle();
    console.log(result);
  }

  private loginUser() {
    this._userService.getUserByEmailWithDetails().subscribe({
      next: (user) => {
        if (user) {
          this._userService.updateUserLoginInfo(environment.appVersion).subscribe({
            next: (result) => {
              if (result) {
                this._fileService.GetFileStorageSettings().subscribe({
                  next: async (settings) => {
                    if (settings) {
                      var processFlow = await this._closedownService.getProcessFlow();
                      this._authService.setFileSettings(settings);
                      this._authService.setUser(user);

                      if (!this.inductionModuleEnable) {
                        var jobId = processFlow.jobId;
                        var siteCode = processFlow.siteCode;
                        var token = processFlow.token;
                        var linkType = processFlow.linkType;
                        if (linkType == 1 &&
                          siteCode != '' &&
                          jobId != '' &&
                          token != '') {
                          this._router.navigate(['closedown', linkType, token]);
                        }
                        else this._browserService.openExternalBroswer('signin/true');
                      }
                      else {
                        switch (user.registerStatus) {
                          case RegisterStatus.Step1: {
                            if (this.rapidGlobalModuleEnable || this.profileInductionModuleEnable) {
                              this._router.navigate(['register/2']);
                            } else {
                              this._router.navigate(['register/3']);
                            }
                            break;
                          }
                          case RegisterStatus.Step2: {
                            this._router.navigate(['register/3']);
                            break;
                          }
                          default: {
                            var jobId = processFlow.jobId;
                            var siteCode = processFlow.siteCode;
                            var token = processFlow.token;
                            var linkType = processFlow.linkType;
                            if (linkType == 1 &&
                              siteCode != '' &&
                              jobId != '' &&
                              token != '') {
                              this._router.navigate(['closedown', linkType, token]);
                              break;
                            }
                            else {
                              if (await this._siteService.getQrSiteId()) {
                                this._router.navigate(['qrsignin']);
                              }
                              else {
                                this._browserService.openExternalBroswer('signin/true');
                              }
                            }
                          }
                        }
                      }
                    }
                    else {
                      alert(this._localizationService.translate('loginuser_filestoragesettings_notfound'));
                    }
                  },
                  error: (err) => {
                    alert(this._localizationService.translate('loginuser_error_getting_filestoragesettings'));
                  }
                });
              }
            },
            error: (err) => {
              if (err.error) {
                alert(err.error);
              }
            }
          });

        }
        else {
          alert(this._localizationService.translate('loginuser_azureid_usernotfound'));
        }
      },
      error: (err) => {
        if (err.error) {
          alert(err.error);
        }
      }
    });
  }

  async register(token: string) {
    await this._authService.loginToAzureB2C("", environment.b2cSignUpPolicy, token);
    this.registerUser();
  }

  private registerUser() {
    this._userService.getUserByEmailWithDetails().subscribe({
      next: (user) => {
        if (user) {
          this._userService.updateUserLoginInfo(environment.appVersion).subscribe({
            next: (result) => {
              if (result) {
                this._fileService.GetFileStorageSettings().subscribe({
                  next: async (settings) => {
                    if (settings) {
                      var processFlow = await this._closedownService.getProcessFlow();
                      this._authService.setFileSettings(settings);
                      this._authService.setUser(user);
                      if (!this.inductionModuleEnable) {
                        var jobId = processFlow.jobId;
                        var siteCode = processFlow.siteCode;
                        var token = processFlow.token;
                        var linkType = processFlow.linkType;
                        if (linkType == 1 &&
                          siteCode != '' &&
                          jobId != '' &&
                          token != '') {
                          this._router.navigate(['closedown', linkType, token]);
                        }
                        else this._browserService.openExternalBroswer('signin/true');
                      }
                      else {
                        this._browserService.openExternalBroswer('register/2');
                      }
                    }
                    else {
                      alert(this._localizationService.translate('loginuser_filestoragesettings_notfound'));
                    }
                  },
                  error: (err) => {
                    alert(this._localizationService.translate('loginuser_error_getting_filestoragesettings'));
                  }
                });
              }
            },
            error: (err) => {
              if (err.error) {
                alert(err.error);
              }
            }
          });


        }
        else {
          alert(this._localizationService.translate('loginuser_azureid_usernotfound'));
        }
      },
      error: (err) => {
        if (err.error) {
          alert(err.error);
        }
      }
    });
  }

  gotoDemoRegisterStepOne() {
    this._router.navigate(['registerdemo/1']);
  }

  public disableSubmitButton(): boolean {
    return !this.loginUserFormGroup.valid;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'emailAddress':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('loginuser_email_required');
        } else if (this.formHasError(form, controlName, 'email')) {
          return this._localizationService.translate('loginuser_email_invalid');
        } else if (this.formHasError(form, controlName, 'notexists')) {
          return this._localizationService.translate('loginuser_email_notexists');
        }
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }

  async installApple() {
    this._browserService.openUrl(await this._configurationService.getApplePlayURL());
  }
  async installGoogle() {
    this._browserService.openUrl(await this._configurationService.getGooglePlayUrl());
  }

  isWebBrowser(): boolean {
    return this._utilsService.isWebBrowser();
  }

  async signUpUser() {
    await this.register("00000000-0000-0000-0000-000000000000");
  }

  onLangSelect(lang: string): void {
    this._localizationService.set(lang).then(() => {
      this.language = lang;
      this._localizationService.useLanguage(lang);
    });
    const locale = this.languages.find(l => l.code === lang)?.azureLocales;
    if (locale) {
      this._localizationService.setAzureLocale(locale);
    }
  }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InductionComponent } from './components/induction/induction.component';
import { LoginPasswordComponent } from './components/login-password/login-password.component';
import { LoginUserComponent } from './components/login-user/login-user.component';
import { QrSignInComponent } from './components/qr-sign-in/qr-sign-in.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SiteSignInComponent } from './components/site-sign-in/site-sign-in.component';
import { SiteSignOutComponent } from './components/site-sign-out/site-sign-out.component';
import { AuthGuard } from './services/auth/auth.guard';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { SignedInVisitorComponent } from './components/signed-in-visitor/signed-in-visitor.component';
import { UserDocumentsComponent } from './components/user-documents/user-documents.component';
import { SiteInductionCompletedComponent } from './components/site-induction-completed/site-induction-completed.component';
import { SiteGuard } from './services/site/site.guard';
import { RegisterStepOneComponent } from './components/register-step-one/register-step-one.component';
import { Register2Component } from './components/register2/register2.component';
import { Register3Component } from './components/register3/register3.component';
import { ClosedownComponent } from './components/closedown/closedown.component';
import { UserManagementListComponent } from './components/user-management-list/user-management-list.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { UserGuard } from './services/user/user.guard';
import { VersionGuard } from './services/version/version.guard';
import { ConfigurationGuard } from './services/configuration/configuration.guard';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { VisitorTypeManagementComponent } from './components/visitor-type-management/visitor-type-management.component';
import { AdminManagementListComponent } from './components/admin-management-list/admin-management-list.component';
import { UserAdminGuard } from './services/user/user-admin.guard';
import { VisitorTypeManagementAddEditComponent } from './components/visitor-type-management-add-edit/visitor-type-management-add-edit.component';
import { SiteManagementComponent } from './components/site-management/site-management.component';
import { PermitWelfareChecksComponent } from './components/permit-welfare-checks/permit-welfare-checks.component';
import { OpenPermitComponent } from './components/open-permit/open-permit.component';
import { ClosePermitComponent } from './components/close-permit/close-permit.component';

/* IMPORTANT NOTE: Please add new path to AndroidManifest.xml (path: android/app/src/main) for Android deep link
and iOS deep link/universal link apple-app-site-association (got 4 files for all env, path: src/well-known)
The reason is Android deep link no exclusion, a similar kind of regex pathAdvancedPattern
only available after API 31 = Android 12 which "might" not even be able to exclude
deep link for Android because pathAdvancedPattern focuses on the path, not query string
, /redirect is currently being excluded, beware if add /redirect<XXXX> */
const routes: Routes = [
  { path: '', component: LoginUserComponent, data : { title : 'Login'}, canActivate:[VersionGuard, ConfigurationGuard]},
  { path: 'redirect', component: RedirectComponent},
  { path: 'redirect/:path', component: RedirectComponent},
  { path: 'login', component: LoginUserComponent, data : { title : 'Login'}, canActivate:[VersionGuard, ConfigurationGuard]},
  { path: 'login/:siteId', component: WelcomeComponent, data : { title : 'Welcome'}, canActivate:[ConfigurationGuard]},
  { path: 'login/user/:siteId', component: LoginUserComponent, data : { title : 'Login'}, canActivate:[VersionGuard, ConfigurationGuard]},
  { path: 'login/:siteId/:calloutId', component: LoginUserComponent, data : { title : 'Login'}, canActivate:[VersionGuard, ConfigurationGuard] },
  { path: 'logout', component: LogoutComponent, data : { title : 'Logout'}, canActivate: [AuthGuard] },
  { path: 'logout/:deleted', component: LogoutComponent, data : { title : 'Logout'}, canActivate: [AuthGuard] },
  { path: 'password/:email', component: LoginPasswordComponent},
  { path: 'password/:email/:siteId', component: LoginPasswordComponent},
  { path: 'password/:email/:siteId/:calloutId', component: LoginPasswordComponent},
  { path: 'password', component: LoginPasswordComponent},
  { path: 'qrsignin', component: QrSignInComponent, data : { title : 'QR Sign In'}, canActivate:[AuthGuard] },
  { path: 'sitesignin/:id', component: SiteSignInComponent, data : { title : 'Site Sign In'}, canActivate:[AuthGuard, ConfigurationGuard] },
  { path: 'signin', component: SignInComponent, data : { title : 'Welcome'}, canActivate:[AuthGuard, VersionGuard, ConfigurationGuard]},
  { path: 'signin/:bypass', component: SignInComponent, data : { title : 'Welcome'}, canActivate:[AuthGuard, ConfigurationGuard] },
  { path: 'induction', component: InductionComponent, data : { title : 'Induction'}, canActivate:[AuthGuard, SiteGuard] },
  { path: 'sitesignout', component: SiteSignOutComponent, data : { title : 'Site Sign Out'}, canActivate:[AuthGuard, SiteGuard, UserGuard] },
  { path: 'userprofile', component: UserProfileComponent, data : { title : 'User Profile'}, canActivate:[AuthGuard, ConfigurationGuard] },
  { path: 'signedinvisitor', component: SignedInVisitorComponent, data : { title : 'Signed In Visitor'}, canActivate:[AuthGuard, SiteGuard] },
  { path: 'userdocuments', component: UserDocumentsComponent, data : { title : 'User Documents'}, canActivate:[AuthGuard] },
  { path: 'siteinductioncompleted', component: SiteInductionCompletedComponent, data : { title : 'Site Induction Completed'}, canActivate:[AuthGuard, SiteGuard, UserGuard] },
  { path: 'registerdemo/1', component: RegisterStepOneComponent},
  { path: 'register/2', component: Register2Component, data : { title : 'User Registration'}, canActivate:[ConfigurationGuard]},
  { path: 'register/3', component: Register3Component, data : { title : 'User Registration'}},
  { path: 'closedown/:type/:token', component: ClosedownComponent },
  { path: 'usermanagementlist', component: UserManagementListComponent, data : { title : 'User Management'}, canActivate:[AuthGuard, ConfigurationGuard] },
  { path: 'usermanagementlist/:resourceId', component: UserManagementListComponent, data : { title : 'User Management'}, canActivate:[AuthGuard, ConfigurationGuard] },
  { path: 'visitortypemanagement', component: VisitorTypeManagementComponent, data: { title: 'Visitor Type Management'}, canActivate:[AuthGuard, ConfigurationGuard, UserAdminGuard]},
  { path: 'adminmanagementlist', component: AdminManagementListComponent, data : { title : 'Admin Management'}, canActivate:[AuthGuard, ConfigurationGuard, UserAdminGuard] },
  { path: 'sitemanagementlist', component: SiteManagementComponent, data : { title : 'Site Management'}, canActivate:[AuthGuard, ConfigurationGuard, UserAdminGuard] },
  { path: 'usermanagementlist/:resourceId', component: UserManagementListComponent, data : { title : 'User Management'}, canActivate:[AuthGuard, ConfigurationGuard] },
  { path: 'visitortypeaddeditmanagement', component: VisitorTypeManagementAddEditComponent, data: { title: 'Visitor Type Add Management'}, canActivate:[AuthGuard, ConfigurationGuard, UserAdminGuard]},
  { path: 'visitortypeaddeditmanagement/:visitorTypeId', component: VisitorTypeManagementAddEditComponent, data: { title: 'Visitor Type Edit Management'}, canActivate:[AuthGuard, ConfigurationGuard, UserAdminGuard]},
  { path: 'permitwelfarechecks', component: PermitWelfareChecksComponent, data: { title: 'Permit Welfare Checks'}, canActivate: [AuthGuard]},
  { path: 'openpermit', component: OpenPermitComponent, data : { title : 'Open Permit'}, canActivate:[AuthGuard, SiteGuard, UserGuard] },
  { path: 'closepermit', component: ClosePermitComponent, data : { title : 'Close Permit'}, canActivate:[AuthGuard, SiteGuard, UserGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

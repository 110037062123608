<div class="main-content">
    <div class="document-header">  
      <h1>{{'lbl_menu_site-management' | translate }}</h1>  
    </div>
    <label id="siteManagementListTitleDetailsLabel">
      {{ ('lbl_site_management_title_details' | translate) }}
    </label>

    <div class="divider"></div>

    <div>
      <mat-label>Brand: </mat-label>  
      <br>
      <mat-form-field appearance="outline" style="margin-right: 1em;">
        <mat-select [(ngModel)]="selectedBrand" placeholder="{{'site_management_list_brand_filter_placeholder' | translate}}" (selectionChange)="brandSelected($event.value)" panelWidth="fit-content">  
          <mat-option [value]="" />
            <mat-option *ngFor="let brand of brandsFilterDisplay" [value]="brand">  
              {{brand.name}}  
            </mat-option>  
        </mat-select> 
      </mat-form-field>
      <br>
      <mat-checkbox [(ngModel)]="includeInactiveBrands" (change)="includeInactiveBrandsChecked($event.checked)">
        {{ ('site_management_list_include_inactive_brands_checkbox' | translate) }}
      </mat-checkbox>
    </div> 
    <br>
    <div>
      <mat-label>Site: </mat-label>
      <br>
      <mat-form-field appearance="outline" style="margin-right: 1em;">
          <mat-select [(ngModel)]="selectedSite" placeholder="{{'site_management_list_site_filter_placeholder' | translate}}" panelWidth="fit-content">  
            <mat-option [value]="" />
            <mat-option *ngFor="let site of sitesFilterDisplay" [value]="site">  
              {{site.name}}  
            </mat-option>  
        </mat-select> 
      </mat-form-field>
      <br>
      <mat-checkbox [(ngModel)]="includeInactiveSites" (change)="includeInactiveSitesChecked($event.checked)">
        {{ ('site_management_list_include_inactive_sites_checkbox' | translate) }}
      </mat-checkbox>
    </div>

    <div class="divider"></div>

    <button mat-raised-button class="search-btn" id="btn_search" data-testid="btn_search" (click)="search()">
      {{ 'site_management_list_search_button' | translate }}
    </button>
    &nbsp;
    <button mat-button class="clearfilters-btn" id="btn_clearfilters" data-testid="btn_clearfilters" (click)="clearSelectedFilters()">
        {{ 'site_management_list_clear_filters_button' | translate }}
    </button>
    
    <div class="divider"></div>

    <div class="form-list">
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <h5>{{'site_management_list_header' | translate }}<mat-icon>help_outline</mat-icon></h5>
        <div fxLayoutAlign="center center">
          <button 
            *ngIf="showSearchButton()"
            data-testid="user_management_list_search_icon"
            mat-fab color="primary" 
            style="margin-right: 1em; width: 4em;" 
            (click)="openSearch()">
            <mat-icon>search</mat-icon>
          </button>
          <div *ngIf="showSearchInput()" >
            <mat-form-field style="margin-right: 1em;" fxLayoutAlign="end end" >
              <mat-label>{{'user_management_search_lbl' | translate }}</mat-label>
              <input matInput
                data-testid="user_management_list_search_input"
                type="text" 
                [formControl]="searchControl" 
                placeholder="{{'user_management_search_lbl' | translate }}">          
              <button 
                data-testid="user_management_list_close_search_icon" 
                matSuffix 
                mat-icon-button 
                (click)="closeSearch()">
                <mat-icon >close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div> 
      </div>
    </div>

    <div class="divider"></div>
    <mat-divider class="table-divider-header"></mat-divider>
    <div>
      <table mat-table data-testid="site_management_list_top_table" 
        [dataSource]="filteredSites" multiTemplateDataRows>  

        <ng-container matColumnDef="Name" >
          <th mat-header-cell *matHeaderCellDef fxFlex="50" fxLayoutAlign="start center">
            {{'site_management_table_site_name_header' | translate }}
          </th>
          <td mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="50" fxLayoutAlign="start center">  
            <span class="ellipsis">{{site.site.name}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Code" >
          <th mat-header-cell *matHeaderCellDef fxFlex="10" fxLayoutAlign="start center">
            {{'site_management_table_site_code_header' | translate }}
          </th>
          <td mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="10" fxLayoutAlign="start center">  
            <span class="ellipsis">{{site.site.code}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Id" >
          <th mat-header-cell *matHeaderCellDef fxFlex="50" fxLayoutAlign="start center">
            {{'site_management_table_site_id_header' | translate }}
          </th>
          <td mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="50" fxLayoutAlign="start center">  
            <span class="ellipsis">{{site.site.id}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Edit" >
          <th mat-header-cell *matHeaderCellDef fxFlex="10" fxLayoutAlign="start center">
            {{'site_management_table_site_edit_header' | translate }}
          </th>
          <td mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="10" fxLayoutAlign="start center">  
            <div>
              <mat-icon matTooltip="{{'site_management_table_site_edit_tooltip' | translate }}" id="site_type_management_list_edit_icon"
                  data-testid="site_type_management_list_edit_icon" color="primary"
                  (click)="editSite(site.site)">
                  edit
              </mat-icon>
          </div>
        </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedSiteColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedSiteColumns;" class="element-row"></tr>
      </table>
      <mat-paginator #paginator
              [pageSizeOptions]="[10, 20, 50]"
              showFirstLastButtons
              aria-label="">
      </mat-paginator>
    </div>  

    <div class="divider"></div>

    <div>
      <button mat-button class="exportToExcel-btn" id="btn_exportToExcel" data-testid="btn_exportToExcel" (click)="exportToExcel()">
        {{ 'site_management_list_export_to_excel_button' | translate }}
      </button>
      &nbsp;
      <button mat-button class="exportToCSV-btn" id="btn_exportToCSV" data-testid="btn_exportToCSV" (click)="exportToCSV()">
          {{ 'site_management_list_export_to_csv_button' | translate }}
      </button>
    </div>

    <div class="divider"></div>

    <div class="close-button">
      <button type="submit" mat-raised-button 
        id="site_management_close"
        data-testid="site_management_list_close"
        (click)="closeSiteManagement()">
        {{'site_management_list_close_button' | translate }}
      </button>
    </div>
</div>
<div class="main-content">

    <div class="header">
        <h1>{{ ('visitor_type_management_component_header' | translate) }}</h1>
    </div>
    <label class="details">
        {{ ('visitor_type_management_component_details' | translate) }}
    </label>

    <div class="divider"></div>

    <div class="form-list">
        <div fxLayout="row" fxLayoutAlign="space-between end" fxLayout="row wrap">
            <h5>{{'visitor_type_management_list_header' | translate }}<mat-icon>help_outline</mat-icon></h5>

            <div fxLayoutAlign="center center" fxLayout="row wrap">

                <div *ngIf="showSearchInput()" class="filter-box" fxLayoutAlign="end baseline">
                    <mat-form-field appearance="outline" style="margin-right: 1em;">
                        <mat-select placeholder="{{'visitor_type_management_filter_placeholder' | translate}}"
                            disableOptionCentering (selectionChange)="selectBrand($event.value)">
                            <mat-option *ngFor="let brand of brands" [value]="brand.id">{{
                                brand.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <button 
                *ngIf="showSearchButton()" 
                    data-testid="visitor_type_management_list_search_icon" 
                    mat-fab color="primary" 
                    style="margin-right: 1em; width: 4em;" 
                    (click)="openSearch()">
                    <mat-icon>search</mat-icon>
                </button>

                <div *ngIf="showSearchInput()">
                    <mat-form-field style="margin-right: 1em;" fxLayoutAlign="end end">
                        <mat-label>{{'visitor_type_management_search_label' | translate }}</mat-label>
                        <input matInput data-testid="visitor_type_management_list_search_input" type="text"
                            [formControl]="searchControl"
                            placeholder="{{'visitor_type_management_search_label' | translate }}"
                            (keydown)="onSearchInputKeydown($event)">
                        <button data-testid="visitor_type_management_list_close_search_icon" matSuffix mat-icon-button
                            (click)="closeSearch()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <button id="btn_add_visitorType" 
                    data-testid="visitor_type_management_list_add" 
                    mat-fab color="primary"
                    style="width: 4em;"
                    (click)="addNewVisitorType()">
                    <mat-icon>add</mat-icon>
                </button>

            </div>

        </div>
        <div class="divider"></div>
        <mat-divider class="table-divider-header"></mat-divider>
        <div>
            <table mat-table data-testid="visitor_type_management_list_top_table" [dataSource]="dataSource" matSort
                (matSortChange)="onSortChange($event)">

                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef fxFlex="60" fxLayoutAlign="start center" mat-sort-header
                        sortActionDescription="Sort by description">
                        {{'visitor_type_management_visitor_type_description_header' | translate }}
                    </th>
                    <td mat-cell class="color-dark-blue" *matCellDef="let displayedVisitorType" fxFlex="60"
                        fxLayoutAlign="start center">
                        <span class="ellipsis">{{displayedVisitorType.description}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Brand">
                    <th mat-header-cell *matHeaderCellDef fxFlex="20" fxLayoutAlign="start center" mat-sort-header
                        sortActionDescription="Sort by brand">
                        {{'visitor_type_management_visitor_type_brand_header' | translate }}
                    </th>
                    <td mat-cell class="color-dark-blue" *matCellDef="let displayedVisitorType" fxFlex="20"
                        fxLayoutAlign="start center">
                        <span class="ellipsis">{{displayedVisitorType.brand.name}} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Domain">
                    <th mat-header-cell *matHeaderCellDef fxFlex="20" fxLayoutAlign="start center" mat-sort-header
                        sortActionDescription="Sort by domain">
                        {{'visitor_type_management_visitor_type_domain_header' | translate }}
                    </th>
                    <td mat-cell class="color-dark-blue" *matCellDef="let displayedVisitorType" fxFlex="20"
                        fxLayoutAlign="start center">
                        <span class="ellipsis">{{displayedVisitorType.domain?.name}} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef fxFlex="10" fxLayoutAlign="center center">
                        {{'visitor_type_management_visitor_type_actions_header' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let displayedVisitorType" fxFlex="10" fxLayoutAlign="center center">
                        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                            <mat-icon matTooltip="{{editToolTip}}" id="visitor_type_management_list_edit_icon"
                                data-testid="visitor_type_management_list_edit_icon" color="primary"
                                (click)="editVisitorType(displayedVisitorType)">
                                edit
                            </mat-icon>
                            <mat-icon matTooltip="{{deleteToolTip}}" id="visitor_type_management_list_delete_icon"
                                data-testid="visitor_type_management_list_delete_icon" color="primary"
                                (click)="deleteVisitorType(displayedVisitorType)">
                                delete
                            </mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedVisitorTypeColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedVisitorTypeColumns;" class="element-row"></tr>

            </table>

            <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50]" [length]="totalItems"
                showFirstLastButtons></mat-paginator>

        </div>
    </div>

    <div class="close-button">
        <button type="submit" mat-raised-button id="visitortypemanagement_close"
            data-testid="visitortypemanagement_close" (click)="closeVisitorTypeManagement()">
            {{'visitor_type_management_close_btn' | translate }}
        </button>
    </div>

</div>
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { Preferences } from '@capacitor/preferences';
import { ConfigurationKey, StorageKey } from '@app/shared/enums';
import { Configuration } from '@app/models/configuration.model';
import { ReferenceService } from '../reference/reference.service';
import { environment } from '@env/environment';
import { LocalizationService } from '@app/components/internationalization/localization.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends BaseService {
  readonly KEY_INDUCTIONMODULEENABLE = ConfigurationKey.INDUCTIONMODULEENABLE;
  readonly KEY_ISSITELISTJOBCOUNTENABLE = ConfigurationKey.ISSITELISTJOBCOUNTENABLE;
  readonly KEY_BLOCKSEARCHINDEXING = ConfigurationKey.BLOCKSEARCHINDEXING;
  readonly KEY_PREVIOUSCONFIGURATIONCHECKTIME = ConfigurationKey.PREVIOUSCONFIGURATIONCHECKTIME;
  readonly KEY_PROFILEINDUCTIONREQUIRED = ConfigurationKey.PROFILEINDUCTIONREQUIRED;
  readonly KEY_PROFILERAPIDGLOBALREQUIRED = ConfigurationKey.PROFILERAPIDGLOBALREQUIRED;
  readonly KEY_CONFIGURATION = StorageKey[StorageKey.CONFIGURATION];

  previousCheckTime = Date.now();
  currentTime = Date.now();

  constructor(
    private _referenceService: ReferenceService,
    private _localizationService: LocalizationService
  ) { 
    super();
  }

  public async getInductionModuleEnable(): Promise<boolean>
  {
    return (await this.getConfigurationValueByKey(ConfigurationKey.INDUCTIONMODULEENABLE))?.toLowerCase() == 'true';
  }

  public async getProfileInductionRequired(): Promise<boolean>
  {
    return (await this.getConfigurationValueByKey(ConfigurationKey.PROFILEINDUCTIONREQUIRED))?.toLowerCase() == 'true';
  }

  public async getProfileRapidGlobalRequired(): Promise<boolean>
  {
    return (await this.getConfigurationValueByKey(ConfigurationKey.PROFILERAPIDGLOBALREQUIRED))?.toLowerCase() == 'true';
  }

  public async getIsSiteListJobCountEnable(): Promise<boolean>
  {
    return (await this.getConfigurationValueByKey(ConfigurationKey.ISSITELISTJOBCOUNTENABLE))?.toLowerCase() == 'true';
  }

  public async getGooglePlayUrl(): Promise<string>
  {
    return await this.getConfigurationValueByKey(ConfigurationKey.GOOGLEPLAYURL);
  }

  public async getApplePlayURL(): Promise<string>
  {
    return await this.getConfigurationValueByKey(ConfigurationKey.APPLESTOREURL);
  }

  public async getPrivacyPolicyLink(): Promise<string>
  {
    return await this.getConfigurationValueByKey(ConfigurationKey.PRIVACYPOLICYLINK);
  }

  public async getTermsConditionLink(): Promise<string>
  {
    return await this.getConfigurationValueByKey(ConfigurationKey.TERMSCONDITIONLINK);
  }

  public async getJobListRenderBatchSize(): Promise<number>
  {
    return Number.parseInt(await this.getConfigurationValueByKey(ConfigurationKey.JOBLISTBATCHSIZE));
  }

  public async getTechnicianUserProfileGuide(): Promise<string>
  {
    return await this.getConfigurationValueByKey(ConfigurationKey.TECHNICALUSERPROFILEGUIDE);
  }

  public async getUserManagementGuide(): Promise<string>
  {
    return await this.getConfigurationValueByKey(ConfigurationKey.USERMANAGEMENTGUIDE);
  }

  public async getSubcontractorVisitsToColesDCsGuide(): Promise<string>
  {
    return await this.getConfigurationValueByKey(ConfigurationKey.SUBCONTRACTORVISITSTOCOLESDCSGUIDE);
  }

  public async getOutOfRangeNotificationIntervalInMinutes(): Promise<number>
  {
    return Number.parseInt(await this.getConfigurationValueByKey(ConfigurationKey.OUTOFRANGENOTIFICATIONINTERVALINMINUTES));
  }

  public async getOutOfRangeNotificationMaxRetryCount(): Promise<number>
  {
    return Number.parseInt(await this.getConfigurationValueByKey(ConfigurationKey.OUTOFRANGENOTIFICATIONMAXRETRYCOUNT));
  }

  public async getNeedHelpDocUrl(): Promise<string>
  {
    return await this.getConfigurationValueByKey(ConfigurationKey.NEEDHELPDOCURL);
  }

  public async getPermitKey(): Promise<string[]>
  {
    var permitKey = await this.getConfigurationValueByKey(ConfigurationKey.PERMITKEY);
    return permitKey.split('|');
  }

  public async getIsMultiLanguageEnable(): Promise<boolean>
  {
    return (await this.getConfigurationValueByKey(ConfigurationKey.ISMULTILANGUAGEENABLE))?.toLowerCase() == 'true';
  }

  public async setPreviousConfigurationCheckTime(previousConfigurationCheckTime: number)
  {
    await Preferences.set({
      key: this.KEY_PREVIOUSCONFIGURATIONCHECKTIME,
      value: JSON.stringify(previousConfigurationCheckTime)
    });
  }

  public async getPreviousConfigurationCheckTime(): Promise<number>
  {
    const item = await Preferences.get({ key : this.KEY_PREVIOUSCONFIGURATIONCHECKTIME });
    if(item.value != null)
    {
      return Number(item.value);
    }

    return 0;
  }

  public async getConfigurationValueByKey(key: ConfigurationKey):Promise<string>
  {
    const config = await this._referenceService.getLocalConfigurations();
    let result = null;
    if(config && config.length > 0){
      result = config.find(c => c.key.toLowerCase() == key.toLowerCase())?.value!;
    }

    return result!;
  }

  async updatePreviousCheckTime()
  {
    this.currentTime = Date.now();
    await this.getPreviousConfigurationCheckTime()
          .then(result => {
            this.previousCheckTime = result;
          });

    if(this.previousCheckTime == null) // Null check shouldn't be hit
    {
      this.previousCheckTime = 0;
    }

    let timeDiffInHours = Math.abs((this.currentTime - this.previousCheckTime) / (60 * 60 * 1000));
    // Update previous check time in local storage to current time if threshold exceeded, maintain otherwise
    if((timeDiffInHours >= environment.configurationCheckInterval))
    {
      await this.setPreviousConfigurationCheckTime(Date.now());
    }
  }

  /**
   * Checks if local settings exists and if settings refresh time threshold has been exceeded.
   * @returns True if local settings does not exist or time threshold exceeded, false otherwise.
   */
  async isLocalConfigValid(): Promise<boolean>
  {
    return new Promise(async resolve => {
      await this._referenceService.getLocalConfigurationKeys().then(keys => {
        let keysList: string[] = [];
        if(keys)
        {
          keysList = keys;
        }
        const localKeysExist = keysList.includes(this.KEY_CONFIGURATION);

        let timeDiffInHours = Math.abs((this.currentTime - this.previousCheckTime) / (60 * 60 * 1000));
        if (!localKeysExist) {
          return resolve(true);
        }
        else if((timeDiffInHours >= environment.configurationCheckInterval))
        {
          return resolve(true);
        }
      });

      return resolve(false);
    });
  }

  public async getAndSetLocalConfigurations(requiredLoading:boolean): Promise<any>
  {
    return new Promise(async (resolve, reject) => {
      this._referenceService.getConfigurations(true, requiredLoading).subscribe({
        next: async (config) => {
          if (config) {
            await this._referenceService.setLocalConfigurations(config);
            resolve(true);
          }
          else {
            alert(this._localizationService.translate("configuration_service_configuration_notexist"));
            resolve(false);
          }
        },
        error: e => {
          reject(alert(this._localizationService.translate("configuration_service_configuration_error")));
        }
      });
    });
  }

  public async shouldRetrieveFromAPI(): Promise<boolean>{
    await this.updatePreviousCheckTime();
    return await this.isLocalConfigValid();
  }
}

<div class="main-content">
    <div class="document-header">  
      <h1>{{'lbl_menu_admin-management' | translate }}</h1>  
    </div>
    <label id="adminManagementListTitleDetailsLabel">
      {{ ('lbl_admin_management_title_details' | translate) }}
    </label>
    <div class="divider"></div>    
    <div class="form-list">
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <h5>{{'admin_management_list_header' | translate }}<mat-icon>help_outline</mat-icon></h5>
        <div fxLayoutAlign="center center">
          <button 
            *ngIf="showSearchButton()" 
            data-testid="admin_management_list_search_icon"
            mat-fab color="primary" 
            style="margin-right: 1em; width: 4em;" 
            (click)="openSearch()">
            <mat-icon>search</mat-icon>
          </button>
          <div *ngIf="showSearchInput()" >
            <mat-form-field style="margin-right: 1em;" fxLayoutAlign="end end" >
              <mat-label>{{'admin_management_search_lbl' | translate }}</mat-label>
              <input matInput
                data-testid="admin_management_list_search_input"
                type="text" 
                [formControl]="searchControl" 
                placeholder="{{'admin_management_search_lbl' | translate }}">          
              <button 
                data-testid="admin_management_list_close_search_icon" 
                matSuffix 
                mat-icon-button 
                (click)="closeSearch()">
                <mat-icon >close</mat-icon>
              </button>
            </mat-form-field>
          </div> 
          <button id="btn_add_user" 
            data-testid="admin_management_list_add" 
            mat-fab color="primary" 
            style="width: 4em;"
            (click)="addUser()">
            <mat-icon>person_add</mat-icon>
          </button>
        </div>      
      </div>
      <div class="divider"></div>
      <mat-divider class="table-divider-header"></mat-divider>
      <div>
        <table mat-table data-testid="admin_management_list_top_table" 
          [dataSource]="filteredUsers" multiTemplateDataRows matSort (matSortChange)="onSortChange($event)">  
          <ng-container matColumnDef="emailAddress" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by emailAddress" fxFlex="100" fxLayoutAlign="start center">
              {{'admin_management_user_email_address_header' | translate }}
            </th>
            <td mat-cell class="color-dark-blue" *matCellDef="let user" fxFlex="100" fxLayoutAlign="start center">  
              <span class="ellipsis">{{user.user.emailAddress}} </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedAdminColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedAdminColumns;" class="element-row"></tr>
        </table>
        <mat-paginator #paginator
                [pageSizeOptions]="[10, 20, 50]"
                showFirstLastButtons
                aria-label="Select page of admin users">
        </mat-paginator>
      </div>  
    </div>
    <div class="close-button">
      <button type="submit" mat-raised-button 
        id="admin_management_close"
        data-testid="admin_management_list_close"
        (click)="closeAdminManagement()">
        {{'admin_management_button_close' | translate }}
      </button>
    </div>
  </div>

<div>

  <div class="profile-form">

    <div fxLayout="row">

      <div fxLayout="column" fxLayoutAlign="center" fxFlex="1 2 auto">

        <div class="color-city-neutral font-size-small-text-bold">{{ ('site_member_validation_lbl_name' | translate) }}
        </div>
        <div class="color-dark-blue font-size-body-text display-label-detail">{{displayfullname}}</div>

        <div class="color-city-neutral font-size-small-text-bold">{{ ('site_member_validation_lbl_phone' | translate) }}
        </div>
        <div class="color-dark-blue font-size-body-text display-label-detail">{{currentUser?.mobileNumber}}</div>

        <div class="color-city-neutral font-size-small-text-bold" *ngIf="objCompliance?.companyName">{{ ('site_member_validation_lbl_company' | translate)
          }}</div>
        <div class="color-dark-blue font-size-body-text display-label-detail" >{{objCompliance?.companyName}}</div>

        <span *ngIf="profileInductionModuleEnable">
          <div class="color-city-neutral font-size-small-text-bold">{{ ('site_member_validation_lbl_induction' |
            translate) }}</div>
          <div class="color-dark-blue font-size-body-text display-label-detail" *ngIf="displayinductionno !== ''">
            {{displayinductionno}}</div>
          <div class="display-empty" *ngIf="displayinductionno === ''">-</div>

          <div class="color-city-neutral font-size-small-text-bold">{{ ('site_member_validation_lbl_expiry' | translate)
            }}</div>
          <div class="color-dark-blue font-size-body-text display-label-detail"
            *ngIf="displayinductionexpirydate !== ''">{{displayinductionexpirydate}}</div>
          <div class="display-empty" *ngIf="displayinductionexpirydate === ''">-</div>
        </span>
      </div>

      <div fxLayoutAlign="end start" fxFlex="1 2 auto">
        <img *ngIf="profileImgLink" src={{profileImgLink}} (error)="profileImgLink=''" class="profile-image">
        <mat-icon *ngIf="!profileImgLink" class="profile-image-empty-icon">account_circle</mat-icon>
      </div>

    </div>
    <div class="divider"></div>
    <mat-divider></mat-divider>


    <span *ngIf="profileRapidGlobalRequired">
      <div class="divider"></div>
      <div fxLayout="row">
        <div class="color-city-blue font-size-body-text-bold">{{ ('site_member_validation_lbl_compliancecheck' |
          translate) }}</div>
      </div>
      <div fxLayout="row">
        <button mat-stroked-button color="basic" disabled>
          <div fxLayout="row" fxFlex="100">
            <div fxLayoutAlign="start center" class="compliance-button-img">
              <img class="site-image" src="/assets/RapidGlobalLogo.png" />
            </div>
            <div fxLayout="column" class="compliance-button-msg" fxLayoutAlign="center center">
              <div class="compliance-status" *ngIf="objCompliance?.statusId">{{
                getCompliantStatus(objCompliance?.statusId) }}</div>
            </div>
            <div class="compliance-button-icon" fxLayoutAlign="end center">
              <mat-icon class="color-city-green"
                *ngIf="objCompliance?.statusId === enumCityComplianceStatus.COMPLIANT || objCompliance?.companyName == defaultCityFMCompanyName">task_alt</mat-icon>
              <mat-icon class="color-city-red"
                *ngIf="objCompliance?.statusId === enumCityComplianceStatus.NOTCOMPLIANT">error</mat-icon>
              <div class="na-icon" *ngIf="objCompliance?.statusId === enumCityComplianceStatus.NOTAPPLICABLE">
                {{ 'site_compliance_status_error_message_icon' | translate }}</div>
            </div>
          </div>
        </button>
      </div>
      <div class="divider"></div>
      <mat-divider></mat-divider>
    </span>

    <span>
      <div class="divider"></div>
      <div fxLayout="row">
        <div class="color-city-blue font-size-body-text-bold">{{ 'site_member_validation_lbl_reason_for_visit_list' |
          translate }}
        </div>
      </div>
      <div *ngIf="!isJobReason()">
        <div class="divider"></div>
        <div fxLayout="row" *ngFor="let reason of siteVisitReasons" fxLayoutAlign="center">
          <div fxLayout="column" fxFlex="95">
            <li class="color-city-blue" *ngIf="reason.calloutReferenceNumber != ''"> {{ reason.calloutReferenceNumber }}
            </li>
          </div>
        </div>
      </div>
      <div *ngIf="isJobReason()">
        <div *ngIf="allocatedJobs.length > 0">
          <div class="divider"></div>
          <ul class="color-city-blue">
            <li *ngFor="let job of allocatedJobs">
              <span>#{{job.referenceNumber}}</span><br>
              <span>{{job.faultServiceName}}</span><br>
              <div *ngIf="job.eta != null">
                <span *ngIf="isReactiveJob(job)">ETA: {{job.eta | date:'dd-MM-yyyy hh:mm a' }}</span>
                <span *ngIf="isPPMJob(job)">Due On: {{job.eta | date:'dd-MM-yyyy' }}</span>
              </div>
              <div class="divider"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="divider"></div>
      <mat-divider></mat-divider>
    </span>

    <span *ngIf="permitQnA">
      <div class="divider"></div>
      <div fxLayout="row">
        <div class="color-city-blue font-size-body-text-bold">{{ 'site_member_validation_lbl_workpermit' | translate }}
        </div>
      </div>
      <div fxLayout="row">
        <span *ngIf="permitQnA.answer && permitQnA.answer == this.permit[1]">{{
          'site_member_validation_lbl_required_workpermit' | translate }}</span>
        <span *ngIf="permitQnA.answer != this.permit[1]">{{ 'site_member_validation_lbl_notrequired_workpermit' |
          translate }}</span>
      </div>
      <div class="divider"></div>
      <mat-divider></mat-divider>
    </span>

    <span>
      <div class="divider"></div>
      <div fxLayout="row">
        <div class="color-city-blue font-size-body-text-bold">{{ 'site_member_validation_lbl_visit_sign_off' | translate
          }}</div>
      </div>
      <div fxLayout="row">
        <span>
          <label>{{visitSignOffQnA.question }}<span class="color-city-red"> *</span></label><br>
          <mat-radio-group [(ngModel)]="visitSignOffQnA.answer" (change)="onClickVisitSignOff(visitSignOffQnA.answer!)">
            <mat-radio-button id="answer_yes" name="detailsMatch"
              value="{{ 'site_member_validation_lbl_visit_sign_off_answer_yes' | translate }}">
              {{ 'site_member_validation_lbl_visit_sign_off_answer_yes' | translate }}
            </mat-radio-button><br>
            <mat-radio-button id="answer_no" name="detailsMatch"
              value="{{ 'site_member_validation_lbl_visit_sign_off_answer_no' | translate }}">
              {{ 'site_member_validation_lbl_visit_sign_off_answer_no' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </span>
      </div>
      <div *ngIf="!visitSignOffQnA.answer">
        <mat-error>{{ 'site_member_validation_lbl_visit_sign_off_required_error' | translate }}</mat-error>
      </div>
      <div class="divider"></div>
      <mat-divider></mat-divider>
    </span>

  </div>

</div>
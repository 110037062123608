<div class="main-content">
  <div class="document-header">  
    <h1>{{'lbl_menu_user-management' | translate }}</h1>  
  </div>
  <label id="userManagementListTitleDetailsLabel">
    {{ ('lbl_user_management_title_details' | translate) }}
  </label>
  <div class="divider"></div>    
  <div class="form-list">
    <div fxLayout="row" fxLayoutAlign="space-between end">
      <h5>{{'user_management_list_header' | translate }}<mat-icon>help_outline</mat-icon></h5>
      <div fxLayoutAlign="center center">
        <button 
          *ngIf="showSearchButton()" 
          data-testid="user_management_list_search_icon"
          mat-fab color="primary" 
          style="margin-right: 1em; width: 4em;" 
          (click)="openSearch()">
          <mat-icon>search</mat-icon>
        </button>
        <div *ngIf="showSearchInput()" >
          <mat-form-field style="margin-right: 1em;" fxLayoutAlign="end end" >
            <mat-label>{{'user_management_search_lbl' | translate }}</mat-label>
            <input matInput
              data-testid="user_management_list_search_input"
              type="text" 
              [formControl]="searchControl" 
              placeholder="{{'user_management_search_lbl' | translate }}">          
            <button 
              data-testid="user_management_list_close_search_icon" 
              matSuffix 
              mat-icon-button 
              (click)="closeSearch()">
              <mat-icon >close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button id="btn_add_user" 
          data-testid="user_management_list_add" 
          mat-fab color="primary" 
          style="width: 4em;"
          (click)="addUser()">
          <mat-icon>person_add</mat-icon>
        </button>
      </div>      
    </div>
    <div class="divider"></div>
    <mat-divider class="table-divider-header"></mat-divider>
    <div>
      <table mat-table data-testid="user_management_list_top_table" 
        [dataSource]="filteredUsers" multiTemplateDataRows>  
        <ng-container matColumnDef="Email" >
          <th mat-header-cell *matHeaderCellDef fxFlex="50" fxLayoutAlign="start center">
            {{'user_management_user_email_address_header' | translate }}
          </th>
          <td mat-cell class="color-dark-blue" *matCellDef="let user" fxFlex="50" fxLayoutAlign="start center">  
            <span class="ellipsis">{{user.user.emailAddress}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="RegisterStatus">
          <th mat-header-cell *matHeaderCellDef fxFlex="15" fxLayoutAlign="start center">
            {{'user_management_register_status_header' | translate }}
          </th>
          <td mat-cell *matCellDef="let user" fxFlex="15" fxLayoutAlign="start center">
            <div *ngIf="user.user.registerStatus == null">{{'user_management_register_status_unregistered' | translate }}</div>
            <div *ngIf="user.user.registerStatus === 1 && inductionModuleEnable">{{'user_management_register_status_not_complete' | translate }}</div>
            <div *ngIf="user.user.registerStatus === 2 && inductionModuleEnable">{{'user_management_register_status_not_complete' | translate }}</div>
            <div *ngIf="user.user.registerStatus === 1 && !inductionModuleEnable">{{'user_management_register_status_complete' | translate }}</div>
            <div *ngIf="user.user.registerStatus === 2 && !inductionModuleEnable">{{'user_management_register_status_complete' | translate }}</div>
            <div *ngIf="user.user.registerStatus === 3">{{'user_management_register_status_complete' | translate }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="InvitationStatus">
          <th mat-header-cell *matHeaderCellDef fxFlex="20" fxLayoutAlign="start center">
            {{'user_management_user_invitation_status' | translate }}
          </th>
          <td mat-cell *matCellDef="let user" fxFlex="20" fxLayoutAlign="start center">
            <div *ngIf="user.invitationStatus != null" >
              {{'user_management_user_invitation_sent' | translate }}{{user.invitationStatus | dateAgo }}
            </div>            
          </td>
        </ng-container>
        <ng-container matColumnDef="ResendInvitation">
          <th mat-header-cell *matHeaderCellDef fxFlex="10" fxLayoutAlign="start center">
            {{'user_management_user_resend_invitation' | translate }}
          </th>
          <td mat-cell *matCellDef="let user" fxFlex="10" fxLayoutAlign="start center">
            <div>
              <mat-icon matTooltip="{{resendInviteToolTip}}"
                *ngIf="user.user.registerStatus == null" 
                id="user_management_list_resendinvite_icon" 
                data-testid="user_management_list_resendinvite_icon"                
                color="primary" 
                (click)="resendInvite(user.user)">
                send
              </mat-icon>
            </div>            
          </td>
        </ng-container>
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef fxFlex="5" fxLayoutAlign="start center">
            {{'user_management_list_edit_tooltip' | translate }}
          </th>
          <td mat-cell *matCellDef="let user" fxFlex="5" fxLayoutAlign="start center">
            <div>
              <mat-icon matTooltip="{{editToolTip}}"
                id="user_management_list_edit_icon" 
                data-testid="user_management_list_edit_icon" 
                color="primary" 
                (click)="editUser(user.user)">
                edit
              </mat-icon>
            </div> 
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedUserColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedUserColumns;" class="element-row"></tr>
      </table>
    </div>  
  </div>
  <div class="close-button">
    <button type="submit" mat-raised-button 
      id="user_management_close"
      data-testid="user_management_list_close"
      (click)="closeUserManagement()">
      {{'user_management_register_close' | translate }}
    </button>
  </div>
</div>